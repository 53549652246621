import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-privacy-request-dialog',
  templateUrl: './privacy-request-dialog.component.html',
  styleUrls: ['./privacy-request-dialog.component.scss'],
  standalone: false,
})
export class PrivacyRequestDialogComponent implements OnInit, AfterContentInit {
  public gdprAccepted = false;

  public content = '???';

  private isSensorstationMember = false;

  constructor(
    private translateService: TranslateService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA)
    public data: { roles: string[] }
  ) {}

  ngOnInit(): void {
    // if authService called its cycle dependency
    this.isSensorstationMember = (this.data?.roles || []).some((role) =>
      [
        'ROLE_SENSORSTATION_ADMIN',
        'ROLE_SENSORSTATION_EDITOR',
        'ROLE_SENSORSTATION_VIEWER',
      ].includes(role)
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  ngAfterContentInit(): void {
    this.readFileContent();
    this.translateService.onLangChange.subscribe(() => {
      this.readFileContent();
    });
  }

  private readFileContent(): void {
    const fn = this.isSensorstationMember
      ? this.translateService.instant('PRIVACY.FILE_MOC')
      : this.translateService.instant('PRIVACY.FILE_LCMM');
    this.http
      .get(fn, {
        responseType: 'text',
      })
      .subscribe({
        next: (content) => {
          this.content = content;
        },
        error: (err) => {
          this.content = JSON.stringify(err);
        },
      });
  }
}
