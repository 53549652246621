import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss'],
  standalone: false,
})
export class PrivacyStatementComponent implements AfterContentInit {
  public content = '???';

  constructor(
    public authService: AuthService,
    private translateService: TranslateService,
    private http: HttpClient
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  ngAfterContentInit(): void {
    this.readFileContent();
    this.translateService.onLangChange.subscribe(() => {
      this.readFileContent();
    });
  }

  private readFileContent(): void {
    this.content = this.translateService.instant('PRIVACY.LOADING');
    let fnKey;
    if (this.authService.isNoLoggedInAppPage()) {
      fnKey = 'APP_PRIVACY.FILE';
    } else if (this.authService.isSensorstationMember()) {
      fnKey = 'PRIVACY.FILE_MOC';
    } else {
      fnKey = 'PRIVACY.FILE_LCMM';
    }
    const fn = this.translateService.instant(fnKey);
    this.http
      .get(fn, {
        responseType: 'text',
      })
      .subscribe({
        next: (content) => {
          this.content = content;
        },
        error: (err) => {
          this.content = JSON.stringify(err);
        },
      });
  }
}
