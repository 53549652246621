import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TripResponse } from 'lcmm-lib-js';
import { TripSectionParameter } from 'src/app/service/trip.service';

@Component({
  selector: 'app-trip-analysis-dialog',
  templateUrl: './trip-analysis-dialog.component.html',
  styleUrls: ['./trip-analysis-dialog.component.scss'],
  standalone: false,
})
export class TripAnalysisDialogComponent implements OnInit {
  public tripResponse: TripResponse;

  public isSectionDialog = false;

  public tripSectionParameter: TripSectionParameter = null;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tripResponse: TripResponse;
      isSectionDialog?: boolean;
      tripSectionParameter: TripSectionParameter;
    }
  ) {
    this.tripResponse = data.tripResponse;
    if (data.isSectionDialog !== undefined && data.isSectionDialog) {
      this.isSectionDialog = true;
      this.tripSectionParameter = data.tripSectionParameter;
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // do nothing
  }
}
