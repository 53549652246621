import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Group, VehicleType } from 'lcmm-lib-js';
import { MatSort } from '@angular/material/sort';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogEntity,
  ConfirmationDialogAction,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { LcmmDataSource } from 'src/app/datasource/lcmm-data-source';
import { Sort } from 'src/app/datasource/page';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { getLeveledGroupName } from 'src/app/utils/utils';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { ResponsiveDesignService } from 'src/app/service/responsiveDesign.service';
import {
  NetworkService,
  NetworkStateEvent,
} from 'src/app/service/network.service';
import { VehicleTypeDialogComponent } from './vehicle-type-dialog/vehicle-type-dialog.component';
import { VehicleTypeCreateDialogComponent } from './vehicle-type-create-dialog/vehicle-type-create-dialog.component';
import {
  VehicleService,
  VehicleTypeQuery,
} from '../../service/vehicle.service';

@Component({
  selector: 'app-vehicle-type',
  templateUrl: './vehicle-type.component.html',
  styleUrls: ['./vehicle-type.component.scss'],
  standalone: false,
})
export class VehicleTypeComponent implements OnInit, OnDestroy, AfterViewInit {
  private className = 'VehicleTypeComponent';

  public displayedColumns: string[] = [
    // 'id',
    'brand',
    'group',
    'series',
    'model',
    'details',
  ];

  public vehicleTypes: VehicleType[];

  public pageSizeOptions: number[] = [5, 10, 25, 100];

  public pageSize = 10;

  private previousPageSize = -1;

  // Link dataSource to MatTable
  public initialSort: Sort = { property: 'brand', order: 'asc' };

  public vehicleTypesData: LcmmDataSource<VehicleType, VehicleTypeQuery>;

  public brandFilter = '';

  public seriesFilter = '';

  public modelFilter = '';

  public groups: Group[] = [];

  public filteredGroups: Observable<Group[]>;

  public groupFilter = new UntypedFormControl();

  public selectedGroup?: Group;

  @ViewChild(MatSort) public sort: MatSort;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  constructor(
    public vehicleService: VehicleService,
    public dialog: MatDialog,
    private userService: UserService,
    public envService: EnvConfigurationService,
    private responsiveDesignService: ResponsiveDesignService,
    public networkService: NetworkService
  ) {
    this.vehicleTypesData = new LcmmDataSource<VehicleType, VehicleTypeQuery>(
      'VehicleType',
      (request, query) => this.vehicleService.vehicleTypesPage(request, query),
      this.initialSort,
      {
        brand: undefined,
        series: undefined,
        model: undefined,
        group: undefined,
      },
      this.networkService
    );
  }

  private resizePage(pageSize: number): void {
    if (this.vehicleTypesData.pageSize !== pageSize) {
      this.vehicleTypesData.fetch(0, pageSize);
    }
  }

  ngAfterViewInit(): void {
    this.resizePage(
      this.responsiveDesignService.register(
        this.className,
        this.resizePage.bind(this)
      )
    );
  }

  ngOnDestroy(): void {
    this.networkService.deregister(this.className);
    this.responsiveDesignService.deregister(this.className);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  private nsCallback(eventType: NetworkStateEvent, msg?: any): void {
    switch (eventType) {
      case NetworkStateEvent.ONLINE:
        this.vehicleTypesData.fetch();
        break;
      case NetworkStateEvent.CLIENTERROR:
      case NetworkStateEvent.SERVERERROR:
        this.paginator.pageIndex -= 1;
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.networkService.register(this.className, this.nsCallback.bind(this));
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups;
        this.filteredGroups = this.groupFilter.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });
  }

  public openEdit(vehicle: VehicleType): void {
    const dialogRef = this.dialog.open(VehicleTypeDialogComponent, {
      width: '80%',
      data: { vehicle, groups: this.groups },
    });
    dialogRef.afterClosed().subscribe((isChanged) => {
      if (isChanged != null && isChanged === true) {
        this.vehicleTypesData.fetch();
      }
    });
  }

  public create(): void {
    const dialogRef = this.dialog.open(VehicleTypeCreateDialogComponent, {
      width: '80%',
      data: { groups: this.groups },
    });
    dialogRef.afterClosed().subscribe((returnedVehicleType) => {
      if (returnedVehicleType != null) {
        this.vehicleTypesData.fetch();
      }
    });
  }

  public delete(vehicleType: VehicleType): void {
    const dialogData: ConfirmationDialogData = {
      entity: ConfirmationDialogEntity.model,
      action: ConfirmationDialogAction.delete,
      data: `${vehicleType.brand}${' '}${vehicleType.series}${' '}${
        vehicleType.model
      }`,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((shouldDelete) => {
      if (shouldDelete) {
        this.vehicleService.deleteVehicleType(vehicleType).subscribe({
          next: () => {
            this.vehicleTypesData.fetch();
          },
        });
      }
    });
  }

  public resetPage(pageSize: number): void {
    this.vehicleTypesData.pageSize = pageSize;
    if (this.previousPageSize > 0 && this.previousPageSize !== pageSize) {
      this.paginator.firstPage();
    }
    this.previousPageSize = pageSize;
  }

  public getCellClass(vehicleType: VehicleType): string {
    if (this.vehicleService.isElectric(vehicleType)) {
      return 'electricCell';
    }
    return null;
  }

  private filterGroups(value: string): Group[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.groups.filter((option) => {
        if (option.groupName) {
          return option.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.groups;
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public queryByFilter(): void {
    this.vehicleTypesData.queryBy({
      brand: this.brandFilter,
      series: this.seriesFilter,
      model: this.modelFilter,
      group: this.groupFilter.value,
    });
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    this.queryByFilter();
  }

  public removeGroup(): void {
    this.selectedGroup = null;
    this.groupFilter.reset();
    this.queryByFilter();
  }

  public leveledGroupName(group: Group): string {
    return getLeveledGroupName(group);
  }

  public fetch(event: PageEvent): void {
    this.vehicleTypesData.fetch(event.pageIndex, event.pageSize);
  }
}
