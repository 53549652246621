import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Group, Vehicle } from 'lcmm-lib-js';
import { VehicleService } from 'src/app/service/vehicle.service';
import { UntypedFormControl } from '@angular/forms';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { DateInputErrorStateMatcher } from 'src/app/utils/error-state-matcher';
import { StandStillMarkerRenderer } from 'src/app/utils/markers/stand-still-marker-renderer';
import { MapHelper } from 'src/app/utils/markers/map-helper';
import { UserService } from 'src/app/service/user.service';
import { sortString } from 'src/app/utils/utils';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TripSectionParameter } from 'src/app/service/trip.service';
import { AuthService } from 'src/app/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DownloadDialogComponent } from 'src/app/download-dialog/download-dialog.component';
import { CompareDialogComponent } from 'src/app/compare-dialog/compare-dialog.component';

@Component({
  selector: 'app-trips-map',
  templateUrl: './trips-map.component.html',
  styleUrls: ['./trips-map.component.scss'],
  standalone: false,
  providers: [DatePipe],
})
export class TripsMapComponent implements AfterViewInit, OnInit {
  public GROUP_IDENTIFIER_SEPARATOR: string;

  private static divIdMap = 'googleGlobalMap';

  private mapHelper: MapHelper;

  @Input() public vehicles: Vehicle[];

  public groups: Group[];

  public filteredGroups: Observable<Group[]>;

  public fcGroup = new UntypedFormControl();

  public selectedGroup?: Group;

  public maxDate: Date = null;

  public minDate: Date = null;

  public fcStartDate = new UntypedFormControl(null);

  public fcEndDate = new UntypedFormControl(null);

  public startDateValid = true;

  public endDateValid = true;

  public errorStateMatcher = new DateInputErrorStateMatcher();

  public infoDialogIsOpen = false;

  public sectionNELat: number = null;

  public sectionNELng: number = null;

  public sectionSWLat: number = null;

  public sectionSWLng: number = null;

  public isInputDisabled = false;

  public loading = false;

  public cutPositions = false;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  @ViewChild(TripsMapComponent.divIdMap, { static: false }) gmap: ElementRef;

  constructor(
    private datePipe: DatePipe,
    private vehicleService: VehicleService,
    private ts: TranslateService,
    public envService: EnvConfigurationService,
    private userService: UserService,
    public authService: AuthService,
    public infoDialog: MatDialog
  ) {
    this.GROUP_IDENTIFIER_SEPARATOR =
      envService.config.groupIdentifierSeparator;

    this.mapHelper = new MapHelper(this.ts, this.datePipe);

    this.mapHelper.registerSectionCallback(this.sectionCallback.bind(this));

    this.vehicleService.vehicles.subscribe((vehicles) => {
      this.vehicles = vehicles;
    });

    this.fcStartDate.statusChanges.subscribe((status) => {
      this.startDateValid = status === 'VALID';
      this.minDate = this.fcStartDate.value;
    });

    this.fcEndDate.statusChanges.subscribe((status) => {
      this.endDateValid = status === 'VALID';
    });
  }

  ngAfterViewInit(): void {
    this.mapHelper.initMap(this.gmap, true, true);
    this.updateMap();
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups.sort(sortString('groupIdentifier'));
        this.filteredGroups = this.fcGroup.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });
  }

  private updateSection(section: TripSectionParameter): void {
    if (section !== null) {
      this.sectionNELat = section.coord.ne.lat;
      this.sectionNELng = section.coord.ne.lng;
      this.sectionSWLat = section.coord.sw.lat;
      this.sectionSWLng = section.coord.sw.lng;
    } else {
      this.sectionNELat = null;
      this.sectionNELng = null;
      this.sectionSWLat = null;
      this.sectionSWLng = null;
    }
  }

  private sectionCallback(section: TripSectionParameter): void {
    this.updateSection(section);
    this.updateMapByParameterChange();
  }

  private filterGroups(groupName: string): Group[] {
    if (typeof groupName === 'string') {
      const filterValue = groupName.toLowerCase();
      return this.groups.filter((option) => {
        if (option.groupName) {
          return option.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.groups;
  }

  private getIcon(vehicle: Vehicle): google.maps.Icon {
    if (vehicle.lastTimestamp !== undefined && vehicle.lastTimestamp !== null) {
      const vehicleTs = Date.parse(vehicle.lastTimestamp as unknown as string);
      const nowTs = new Date().getTime();
      // last timestamp older than 15 min?
      if (vehicleTs < nowTs - 15 * 60 * 1000) {
        // last timestamp older than 8 h?
        if (vehicleTs < nowTs - 8 * 60 * 60 * 1000) {
          return StandStillMarkerRenderer.gooIconFinished8H;
        }
        return StandStillMarkerRenderer.gooIconFinished;
      }
    }
    if (!vehicle.standStillTime) {
      return StandStillMarkerRenderer.gooIconRunning;
    }
    if (vehicle.standStillTime < 5 * 60) {
      return StandStillMarkerRenderer.gooIconStsFive;
    }
    if (vehicle.standStillTime < 30 * 60) {
      return StandStillMarkerRenderer.gooIconStsThirty;
    }
    if (vehicle.standStillTime < 60 * 60) {
      return StandStillMarkerRenderer.gooIconStsSixty;
    }
    return StandStillMarkerRenderer.gooIconSts;
  }

  private getZIndex(vehicle: Vehicle): number {
    if (vehicle.lastTimestamp !== undefined && vehicle.lastTimestamp !== null) {
      const vehicleTs = Date.parse(vehicle.lastTimestamp as unknown as string);
      const nowTs = new Date().getTime();
      // last timestamp older than 15 min?
      if (vehicleTs < nowTs - 15 * 60 * 1000) {
        // last timestamp older than 8 h?
        if (vehicleTs < nowTs - 8 * 60 * 60 * 1000) {
          return 0;
        }
        return 1;
      }
    }
    if (!vehicle.standStillTime) {
      return 6;
    }
    if (vehicle.standStillTime < 5 * 60) {
      return 2;
    }
    if (vehicle.standStillTime < 30 * 60) {
      return 3;
    }
    if (vehicle.standStillTime < 60 * 60) {
      return 4;
    }
    return 5;
  }

  private buildInfoContent(vehicle: Vehicle): string {
    let date = ' - ';
    if (vehicle.lastTimestamp) {
      date = this.datePipe.transform(
        vehicle.lastTimestamp,
        this.ts.instant('DATEFORMAT')
      );
    }
    const content = `
    <b>${this.ts.instant('VEHICLE.NAME')}: </b>${vehicle.name}<br>
    <b>${this.ts.instant('VEHICLE.GROUP')}: </b>${vehicle.groupName}<br>
    <b>${this.ts.instant('VEHICLE.BRAND')}: </b>${vehicle.brand}<br>
    <b>${this.ts.instant('VEHICLE.MODEL')}: </b>${vehicle.model}<br>
    <b>${this.ts.instant('VEHICLE.SERIES')}: </b>${vehicle.series}<br>
    <b>${this.ts.instant('VEHICLE.LASTPOSITION')}: </b>${date}
    `;
    return content;
  }

  private disableInput(): void {
    this.isInputDisabled = true;
    this.disableMap(true);
  }

  private enableInput(): void {
    this.loading = false;
    this.isInputDisabled = false;
    this.disableMap(false);
  }

  private disableMap(disable: boolean): void {
    if (disable) {
      document.getElementById('disableMapDiv').style.zIndex = '1000000000';
    } else {
      document.getElementById('disableMapDiv').style.zIndex = '-1000000000';
    }
  }

  public download(): void {
    this.disableInput();
    DownloadDialogComponent.open(
      this.infoDialog,
      {
        groupIdentifier: this.selectedGroup.groupIdentifier,
        startDate: this.fcStartDate.value,
        endDate: this.fcEndDate.value,
        bounds: this.mapHelper.getSelectedSectionBounds(),
        cutToBounds: this.cutPositions,
      },
      this.enableInput.bind(this)
    );
  }

  public compare(): void {
    this.disableInput();
    CompareDialogComponent.open(
      this.infoDialog,
      {
        groupIdentifier: this.selectedGroup.groupIdentifier,
        startDate: this.fcStartDate.value,
        endDate: this.fcEndDate.value,
        bounds: this.mapHelper.getSelectedSectionBounds(),
        cutToBounds: this.cutPositions,
      },
      this.enableInput.bind(this)
    );
  }

  public areCoordinatesSetWrong(): boolean {
    return (
      (this.sectionNELat != null &&
        this.sectionSWLat != null &&
        this.sectionNELat < this.sectionSWLat) ||
      (this.sectionNELng != null &&
        this.sectionSWLng != null &&
        this.sectionNELng < this.sectionSWLng)
    );
  }

  public areAllCoordinatesSetCorrectly(): boolean {
    return (
      this.sectionNELat != null &&
      this.sectionNELng != null &&
      this.sectionSWLat != null &&
      this.sectionSWLng != null &&
      !this.areCoordinatesSetWrong()
    );
  }

  private isNoCoordinateSet(): boolean {
    return (
      this.sectionNELat === null &&
      this.sectionNELng === null &&
      this.sectionSWLat === null &&
      this.sectionSWLng === null
    );
  }

  private isNoOrAllCoordinatesSetRight(): boolean {
    return this.areAllCoordinatesSetCorrectly() || this.isNoCoordinateSet();
  }

  private isStartSet(): boolean {
    return this.fcStartDate.value;
  }

  private isEndSet(): boolean {
    return this.fcEndDate.value;
  }

  private isStartSetAndValid(): boolean {
    return this.isStartSet() && this.startDateValid;
  }

  private isEndSetAndValid(): boolean {
    return this.isEndSet() && this.endDateValid;
  }

  private isStartUnsetOrSetAndValid(): boolean {
    return !this.isStartSet() || this.startDateValid;
  }

  private isEndUnsetOrSetAndValid(): boolean {
    return !this.isEndSet() || this.endDateValid;
  }

  private areStartEndSetValid(): boolean {
    return (
      this.isStartSetAndValid() &&
      this.isEndSetAndValid() &&
      (this.fcEndDate.value as Date) >= (this.fcStartDate.value as Date)
    );
  }

  private areStartEndUnsetOrSetValid(): boolean {
    return (
      !(this.isStartSet() && this.isEndSet()) || this.areStartEndSetValid()
    );
  }

  public disableDownloadButton(): boolean {
    return (
      !this.isNoOrAllCoordinatesSetRight() ||
      !this.isGroupSelected() ||
      !this.isStartUnsetOrSetAndValid() ||
      !this.isEndUnsetOrSetAndValid() ||
      !this.areStartEndUnsetOrSetValid() ||
      this.isInputDisabled
    );
  }

  public disableCompareButton(): boolean {
    return (
      !this.isNoOrAllCoordinatesSetRight() ||
      !this.isGroupSelected() ||
      !this.areStartEndSetValid() ||
      this.isInputDisabled
    );
  }

  private isGroupSelected(): boolean {
    if (this.selectedGroup) {
      return true;
    }
    return false;
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    if (group === null) {
      this.fcGroup.reset();
    }

    this.updateMapByParameterChange();
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public updateMapByParameterChange(): void {
    // this.resetLoading();
    // this.updateMap();
  }

  public updateMapSection(): void {
    this.mapHelper.setSection(
      this.sectionNELat,
      this.sectionNELng,
      this.sectionSWLat,
      this.sectionSWLng
    );
    this.updateMapByParameterChange();
  }

  /*
  private getGroupName(): string {
    if (this.isGroupSelected()) {
      return `${this.selectedGroup.groupName}.`;
    }
    return null;
  }
  */

  private updateMap(): void {
    if (this.vehicles) {
      let sw: google.maps.LatLng = null;
      let ne: google.maps.LatLng = null;
      this.mapHelper.removeMarkers();
      this.vehicles
        /*
        .filter((v) => {
          if (this.startDate.value === null) {
            return true;
          }
          if (v.lastTimestamp === undefined || v.lastTimestamp === null) {
            return false;
          }
          const vehicleTs = Date.parse(v.lastTimestamp as unknown as string);
          const filterTs = this.startDate.value;
          return vehicleTs >= filterTs;
        })
        .filter((v) => {
          if (this.endDate.value === null) {
            return true;
          }
          if (v.lastTimestamp === undefined || v.lastTimestamp === null) {
            return false;
          }
          const vehicleTs = Date.parse(v.lastTimestamp as unknown as string);
          const filterTs = this.endDate.value;
          return vehicleTs <= filterTs;
        })
        .filter((v) => {
          const gn = this.getGroupName();
          return gn === null || v.groupName === gn;
        })
        */
        .forEach((vehicle) => {
          if (vehicle.lastLatitude && vehicle.lastLongitude) {
            // Set first position for boundary determination
            if (sw === null) {
              sw = new google.maps.LatLng(
                vehicle.lastLatitude,
                vehicle.lastLongitude
              );
            }
            if (ne === null) {
              ne = new google.maps.LatLng(
                vehicle.lastLatitude,
                vehicle.lastLongitude
              );
            }
            this.mapHelper.addMarker(
              new google.maps.LatLng(
                vehicle.lastLatitude,
                vehicle.lastLongitude
              ),
              vehicle.name,
              this.getIcon(vehicle),
              this.getZIndex(vehicle),
              this.buildInfoContent(vehicle)
            );
            // If "environment.showEurope" is set: Just show trips within Europe
            if (
              !this.envService.config.showEurope ||
              (vehicle.lastLongitude < 25 &&
                vehicle.lastLongitude > -25 &&
                vehicle.lastLatitude > 34)
            ) {
              if (vehicle.lastLatitude < sw.lat()) {
                sw = new google.maps.LatLng(vehicle.lastLatitude, sw.lng());
              }
              if (vehicle.lastLongitude < sw.lng()) {
                sw = new google.maps.LatLng(sw.lat(), vehicle.lastLongitude);
              }
              if (vehicle.lastLatitude > ne.lat()) {
                ne = new google.maps.LatLng(vehicle.lastLatitude, ne.lng());
              }
              if (vehicle.lastLongitude > ne.lng()) {
                ne = new google.maps.LatLng(ne.lat(), vehicle.lastLongitude);
              }
            }
          }
        });
      this.mapHelper.fitMapBounds(new google.maps.LatLngBounds(sw, ne));
    }
  }
}
