<app-overlay-spinner *ngIf="networkService.offlineOrLoading || !users || !groups"></app-overlay-spinner>

<div class="filter">
  
  <mat-form-field>
    <mat-label>{{ 'VEHICLE.NAME' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryByFilter()" (blur)="queryByFilter()" [(ngModel)]="globalFilter"/>
    <button mat-button *ngIf="globalFilter" matSuffix mat-icon-button aria-label="Clear" (click)="globalFilter=''; queryByFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field hideRequiredMarker="false">
    <mat-label>{{ 'VEHICLE.USER' | translate }}</mat-label>
    <mat-chip-grid #chipList1 aria-label="User selection">
      <mat-chip-row class="vehicles-mat-chip"
        *ngIf="selectedUser"
        (removed)="removeUser()">
        {{selectedUser.userName}}
        <mat-icon matChipRemove >cancel</mat-icon>
      </mat-chip-row>
      <input *ngIf="!selectedUser"
      type="text"
      placeholder
      required
      matInput
      [formControl]="userFilter"
      [matAutocomplete]="auto1"
      [matChipInputFor]="chipList1"
    />
    </mat-chip-grid>
    <mat-autocomplete
      #auto1="matAutocomplete"
      (optionSelected)="selectUser($event.option.value)"
      [displayWith]="displayUser"
    >
      <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
        {{ user.userName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field hideRequiredMarker="false">
    <mat-label>{{ 'TRIP.GROUPNAME' | translate }}</mat-label>
    <mat-chip-grid #chipList2 aria-label="Group selection">
      <mat-chip-row class="vehicles-mat-chip"
        *ngIf="selectedGroup"
        (removed)="removeGroup()">
        {{selectedGroup.groupName}}
        <mat-icon matChipRemove >cancel</mat-icon>
      </mat-chip-row>
      <input *ngIf="!selectedGroup"
      type="text"
      placeholder
      required
      matInput
      [formControl]="groupFilter"
      [matAutocomplete]="auto2"
      [matChipInputFor]="chipList2"
    />
    </mat-chip-grid>
    <mat-autocomplete
      #auto2="matAutocomplete"
      (optionSelected)="selectGroup($event.option.value)"
      [displayWith]="displayGroup"
    >
      <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
        <span>
          {{ group.groupIdentifier | convertDotGroupName }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

</div>

<button mat-raised-button (click)="create()">
  {{ 'VEHICLE.TITLECREATE' | translate }}
</button>

<table
  mat-table
  matSort
  [dataSource]="vehiclesData"
  class="mat-elevation-z8"
  (matSortChange)="vehiclesData.sortBy({ property: $event.active, order: $event.direction })"
  matSortActive="_name"
  matSortDirection="desc"
  matSortStart="desc"
>
  <ng-container matColumnDef="_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'VEHICLE.NAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span [class]="getCellClass(row)" *ngIf="row.originalVehicleId && row.lastModified">
        {{ row.name }} {{ row.lastModified }}
      </span>
      <span [class]="getCellClass(row)" *ngIf="!row.originalVehicleId">
        {{ row.name }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'VEHICLE.USER' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span>{{ getUserNames(row.user) }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="group">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'VEHICLE.GROUP' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.groupName">{{ row.groupName | convertDotGroupName }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="lastTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'VEHICLE.LASTPOSITION' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.lastTimestamp">{{ row.lastTimestamp | date: 'dd/MM/yyyy, HH:mm' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="lastModified">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'VEHICLE.LASTMODIFIED' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.lastModified">{{ row.lastModified | date: 'dd/MM/yyyy, HH:mm' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'DETAILS' | translate }}
    </th>
    <td mat-cell *matCellDef="let row; let i = dataIndex" class="details">
      <div class="editButtons">
        <button *ngIf="!row.disabled && !row.originalVehicleId" mat-raised-button (click)="openEdit(row, true)">
          {{ 'EDIT' | translate }}
        </button>
        <button *ngIf="!row.disabled && row.originalVehicleId && row.lastModified" mat-raised-button (click)="openEdit(row, false)">
          {{ 'SHOW' | translate }}
        </button>
        <button *ngIf="!row.disabled" mat-raised-button (click)="disableVehicle(row)" >
          {{ 'DISABLE' | translate }}
        </button>
        <button *ngIf="row.disabled" mat-raised-button (click)="enableVehicle(row)">
          {{ 'ENABLE' | translate }}
        </button>
        <button *ngIf="row.disabled" mat-raised-button (click)="deleteVehicle(row)">
          {{ 'DELETE' | translate }}
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>

<mat-paginator 
  *ngIf="vehiclesData.page$ | async as page"
  [length]="page.totalElements"
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="page.size"
  [pageIndex]="page.number"
  (page)="fetch($event)"
>
</mat-paginator>
