import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

export type InfoComponentCloseCallback = () => void;

export interface InfoComponentData {
  title?: string;
  text?: string;
  buttonTitle?: string;
  doNotTranslateTitle?: boolean;
  doNotTranslateText?: boolean;
  doNotTranslateButtonTitle?: boolean;
}

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  standalone: false,
})
export class InfoComponent {
  public title = 'SEARCH_FAILED';

  public doNotTranslateTitle = false;

  public text = 'SEARCH_FAILED_TIPP';

  public doNotTranslateText = false;

  public buttonTitle = 'CONTINUE';

  public doNotTranslateButtonTitle = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data?: InfoComponentData) {
    if (data) {
      if (data.title) this.title = data.title;
      if (data.doNotTranslateTitle)
        this.doNotTranslateTitle = data.doNotTranslateTitle;
      if (data.text) this.text = data.text;
      if (data.doNotTranslateText)
        this.doNotTranslateText = data.doNotTranslateText;
      if (data.buttonTitle) this.buttonTitle = data.buttonTitle;
      if (data.doNotTranslateButtonTitle)
        this.doNotTranslateButtonTitle = data.doNotTranslateButtonTitle;
    }
  }

  public static open(
    dialog: MatDialog,
    onClose?: InfoComponentCloseCallback,
    infoData?: InfoComponentData,
    modal?: boolean
  ): MatDialogRef<InfoComponent> {
    const dialogRef = dialog.open(InfoComponent, {
      data: infoData,
      disableClose: modal === undefined || modal === null || modal,
    });
    if (onClose) {
      dialogRef.afterClosed().subscribe(() => {
        onClose();
      });
    }
    return dialogRef;
  }
}
