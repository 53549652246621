
<mat-card appearance="outlined" class="card">
    <div class="grafana-container" style="text-align: center;">
        <iframe id="sensorstation-aqdm-1"
                [src]="url1 | sensorstationsUrl"
                width="100%" height="100%" frameborder="0" style="border:none; overflow:hidden;"
                scrolling="no"></iframe>
        <iframe id="sensorstation-aqdm-2"
                [src]="url2 | sensorstationsUrl"
                width="100%" height="100%" frameborder="0" style="border:none; overflow:hidden;"
                scrolling="no"></iframe>
    </div>
</mat-card>