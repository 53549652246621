<app-overlay-spinner *ngIf="networkService.offlineOrLoading || !groups"></app-overlay-spinner>

<div class="filter">

  <mat-form-field>
    <mat-label>{{ 'VEHICLE.BRAND' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryByFilter()" (blur)="queryByFilter()" [(ngModel)]="brandFilter">
    <button mat-button *ngIf="brandFilter" matSuffix mat-icon-button aria-label="Clear" (click)="brandFilter=''; queryByFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'VEHICLE.SERIES' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryByFilter()" (blur)="queryByFilter()" [(ngModel)]="seriesFilter"/>
    <button mat-button *ngIf="seriesFilter" matSuffix mat-icon-button aria-label="Clear" (click)="seriesFilter=''; queryByFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'VEHICLE.MODEL' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryByFilter()" (blur)="queryByFilter()" [(ngModel)]="modelFilter"/>
    <button mat-button *ngIf="modelFilter" matSuffix mat-icon-button aria-label="Clear" (click)="modelFilter=''; queryByFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field hideRequiredMarker="false">
    <mat-label>{{ 'TRIP.GROUPNAME' | translate }}</mat-label>
    <mat-chip-grid #chipListGroup aria-label="Group selection">
      <mat-chip-row class="vehicle-types-mat-chip"
        *ngIf="selectedGroup"
        (removed)="removeGroup()">
        {{selectedGroup.groupName}}
        <mat-icon matChipRemove >cancel</mat-icon>
      </mat-chip-row>
      <input *ngIf="!selectedGroup"
      type="text"
      placeholder
      required
      matInput
      [formControl]="groupFilter"
      [matAutocomplete]="autoGroup"
      [matChipInputFor]="chipListGroup"
    />
    </mat-chip-grid>
    <mat-autocomplete
      #autoGroup="matAutocomplete"
      (optionSelected)="selectGroup($event.option.value)"
      [displayWith]="displayGroup"
    >

      <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
        <span>
          {{ group.groupIdentifier | convertDotGroupName }}
        </span>
      </mat-option>

    </mat-autocomplete>
  </mat-form-field>
  
</div>

<button mat-raised-button (click)="create()">
  {{ 'VEHICLETYPE.TITLECREATE' | translate }}
</button>

<table 
  mat-table 
  matSort 
  [dataSource]="vehicleTypesData"
  class="mat-elevation-z8"
  (matSortChange)="vehicleTypesData.sortBy({ property: $event.active, order: $event.direction })"
  matSortActive="name"
  matSortDirection="desc"
  matSortStart="desc"
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'ID' | translate }}
    </th>
    <td mat-cell *matCellDef="let row; let index = index">
      {{ row.id }}
    </td>
  </ng-container>

  <ng-container matColumnDef="brand">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'VEHICLE.BRAND' | translate }}
    </th>
    <td mat-cell *matCellDef="let row; let index = index">
      {{ row.brand }}
    </td>
  </ng-container>

  <ng-container matColumnDef="group">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'VEHICLE.GROUP' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.groupName">{{ row.groupName | convertDotGroupName }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="series">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'VEHICLE.SERIES' | translate }}
    </th>
    <td mat-cell *matCellDef="let row; let index = index">
      {{ row.series }}
    </td>
  </ng-container>

  <ng-container matColumnDef="model">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'VEHICLE.MODEL' | translate }}
    </th>
    <td mat-cell *matCellDef="let row; let index = index">
      <span [class]="getCellClass(row)">
        {{ row.model }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="mass">
    <th mat-header-cell *matHeaderCellDef translate="VEHICLE.MASS"></th>
    <td mat-cell *matCellDef="let row; let index = index">
      {{ row.mass }}
    </td>
  </ng-container>

  <ng-container matColumnDef="efficiency">
    <th mat-header-cell *matHeaderCellDef translate="VEHICLE.EFFICIENCY"></th>
    <td mat-cell *matCellDef="let row; let index = index">
      {{ row.efficiency }}
    </td>
  </ng-container>

  <ng-container matColumnDef="fuelValue">
    <th mat-header-cell *matHeaderCellDef translate="VEHICLE.FUELVALUE"></th>
    <td mat-cell *matCellDef="let row; let index = index">
      {{ row.fuelValue }}
    </td>
  </ng-container>

  <ng-container matColumnDef="emission">
    <th mat-header-cell *matHeaderCellDef translate="VEHICLE.EMISSIONS"></th>
    <td mat-cell *matCellDef="let row; let index = index">
      {{ row.emission }}
    </td>
  </ng-container>

  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef translate="VEHICLE.TIME"></th>
    <td mat-cell *matCellDef="let row; let index = index">
      {{ row.emission }}
    </td>
  </ng-container>

  <ng-container matColumnDef="thumbnail">
    <td mat-cell *matCellDef="let row; let index = index"></td>
  </ng-container>

  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef translate="DETAILS"></th>
    <td mat-cell *matCellDef="let row; let index = index">
      <button mat-raised-button class="editButton" (click)="openEdit(row)">
        {{ 'EDIT' | translate }}
      </button>
      <button mat-raised-button (click)="delete(row)">
        {{ 'DELETE' | translate }}
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  *ngIf="vehicleTypesData.page$ | async as page"
  [length]="page.totalElements"
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="page.size"
  [pageIndex]="page.number"
  (page)="fetch($event)"
>
</mat-paginator>
