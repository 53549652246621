import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Group } from 'lcmm-lib-js';
import { Sensorstation } from 'sensorstation-lib-js';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-sensorstation-form',
  templateUrl: './sensorstation-form.component.html',
  styleUrls: ['./sensorstation-form.component.scss'],
  viewProviders: [
    // See https://stackoverflow.com/questions/53588169/how-to-use-formgroupname-inside-child-components
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  standalone: false,
})
export class SensorstationFormComponent implements OnInit {
  public form: UntypedFormGroup;

  public group: Group;

  @Input() public groups: Group[];

  @Input() public sensorstation: Sensorstation;

  @Input() public allowEditing: boolean;

  private mySensorstation: Sensorstation;

  constructor(
    private ctrlContainer: FormGroupDirective, // Parentform
    private fb: UntypedFormBuilder,
    public envService: EnvConfigurationService
  ) {}

  ngOnInit(): void {
    this.mySensorstation = this.sensorstation;
    const sensorstationForm = this._createSensorstationFormGroup();
    this.form = this.ctrlContainer.form;
    this.form.addControl('sensorstationForm', sensorstationForm);

    if (!this.allowEditing) {
      this.form.disable();
    }
  }

  private _createSensorstationFormGroup(): UntypedFormGroup {
    const sensorstationForm = this.fb.group(this.mySensorstation);
    sensorstationForm.patchValue({ deviceId: this.mySensorstation.deviceId });
    if (this.mySensorstation.deviceId) {
      sensorstationForm.patchValue(this.mySensorstation);
      sensorstationForm.get('group').disable();
      sensorstationForm.get('deviceId').disable();
    }
    return sensorstationForm;
  }
}
