import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Group } from 'lcmm-lib-js';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogEntity,
  ConfirmationDialogAction,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/service/auth.service';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { UserService } from 'src/app/service/user.service';
import { correctDescSorting, sortGroups } from 'src/app/utils/group-sort';
import { getLeveledGroupName } from 'src/app/utils/utils';
import { ENTER } from '@angular/cdk/keycodes';
import { ResponsiveDesignService } from 'src/app/service/responsiveDesign.service';
import { NetworkService } from 'src/app/service/network.service';
import { GroupCreateDialogComponent } from './group-create-dialog/group-create-dialog.component';
import { GroupCodeDialogComponent } from './group-code-dialog/group-code-dialog.component';

@Component({
  selector: 'app-lcmm-groups',
  templateUrl: './lcmm-groups.component.html',
  styleUrls: ['./lcmm-groups.component.scss'],
  standalone: false,
})
export class LcmmGroupsComponent implements OnInit, AfterViewInit, OnDestroy {
  private className = 'LcmmGroupsComponent';

  public groups: Group[] = [];

  public filteredGroups: Group[] = [];

  public group: Group;

  public groupNameFilter = '';

  public selectedLevels: number[] = [];

  public levels: number[] = [];

  public separatorKeysCodes: number[] = [ENTER];

  public displayedColumns: string[] = ['level', 'groupName', 'delete'];

  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];

  public pageSize = 10;

  public selectedGroup?: Group;

  public groupsListTable: MatTableDataSource<Group> = new MatTableDataSource();

  private previousPageSize = -1;

  public withPositions = false;

  @ViewChild(MatSort) public sort: MatSort;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    public userService: UserService,
    public envService: EnvConfigurationService,
    public authService: AuthService,
    private responsiveDesignService: ResponsiveDesignService,
    public networkService: NetworkService
  ) {}

  private resize(pageSize: number): void {
    if (this.pageSize !== pageSize) {
      this.resetPage(pageSize);
      this.filterGroups();
    }
  }

  public resetPage(pageSize: number): void {
    if (this.paginator) {
      if (this.previousPageSize > 0 && this.previousPageSize !== pageSize) {
        this.paginator.firstPage();
      }
      this.paginator.pageSize = pageSize;
      this.pageSize = pageSize;
    }
    this.previousPageSize = pageSize;
  }

  ngAfterViewInit(): void {
    this.resize(
      this.responsiveDesignService.register(
        this.className,
        this.resize.bind(this)
      )
    );
    this.groupsListTable.sort = this.sort;
    this.groupsListTable.paginator = this.paginator;
    const sortFunction = this.sortData.bind(this);
    this.groupsListTable.sortData = sortFunction;
  }

  ngOnDestroy(): void {
    this.responsiveDesignService.deregister(this.className);
  }

  ngOnInit(): void {
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups;
        this.determineLevels();
        this.filterGroups();
      }
    });
  }

  private determineLevels(): void {
    this.groups.forEach((group) => {
      if (
        group.level !== undefined &&
        group.level !== null &&
        !this.levels.includes(group.level)
      ) {
        this.levels.push(group.level);
      }
    });
    this.levels = this.levels.sort((a, b) => a - b);
  }

  public create(): void {
    const dialogRef = this.dialog.open(GroupCreateDialogComponent, {
      width: '80%',
      data: { groups: this.groups },
    });

    dialogRef.afterClosed().subscribe((createdGroup) => {
      if (createdGroup != null) {
        this.groupsListTable._updateChangeSubscription();
        this.resetPage(this.previousPageSize);
      }
    });
  }

  public sortData(data: Group[], sort: MatSort): Group[] {
    const { active } = sort;
    const { direction } = sort;

    if (!active) {
      return data;
    }

    if (direction === 'asc') {
      return sortGroups(data, active, 'asc');
    }
    if (direction === 'desc') {
      const descSortedGroups: Group[] = [...sortGroups(data, active, 'desc')];
      const correctedGroups: Group[] = [
        ...correctDescSorting(
          descSortedGroups,
          this.envService.config.groupIdentifierSeparator
        ),
      ];
      return correctedGroups;
    }
    return data;
  }

  public delete(group: Group): void {
    const subGroupsCount = this.userService.countSubGroups(group);
    const dialogData: ConfirmationDialogData = {
      entity: ConfirmationDialogEntity.group,
      action:
        subGroupsCount > 0
          ? ConfirmationDialogAction.deleteException
          : ConfirmationDialogAction.delete,
      data: group.groupName,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((shouldDelete) => {
      if (shouldDelete) {
        this.userService.countSubGroups(group);
        this.userService.deleteGroup(group).subscribe(() => {
          this.groupsListTable._updateChangeSubscription();
          this.filterGroups();
        });
      }
    });
  }

  public showGroupCode(group: Group): void {
    this.dialog.open(GroupCodeDialogComponent, {
      width: '80%',
      data: group,
    });
  }

  public getGroupLevel(groupIdentifier: string): number {
    if (
      this.userService.getGroupIdentifier() !== null &&
      this.userService.getGroupIdentifier() ===
        this.userService.getEmptyGroupIdentifier()
    ) {
      return 0;
    }
    if (groupIdentifier === null) {
      return null;
    }
    if (this.userService.getGroupIdentifier()) {
      const gp = groupIdentifier.split(this.userService.getGroupIdentifier());
      if (gp.length > 1) {
        return gp[1].split('.').length;
      }
    }
    return groupIdentifier.split('.').length - 1;
  }

  public leveledGroupName(group: Group): string {
    return getLeveledGroupName(group);
  }

  public filterGroups(): void {
    let fgs = this.groups;
    if (this.groupNameFilter.length) {
      fgs = fgs.filter((group) => {
        if (group.groupName) {
          return group.groupName
            .toLocaleUpperCase()
            .includes(this.groupNameFilter.toLocaleUpperCase());
        }
        return false;
      });
    }
    if (this.selectedLevels && this.selectedLevels.length > 0) {
      fgs = fgs.filter((group) => {
        if (group.level) {
          return this.selectedLevels.includes(group.level);
        }
        return false;
      });
    }
    this.groupsListTable.data = fgs;
    this.groupsListTable._updateChangeSubscription();
  }

  public displayLevel(level: number): string {
    return level.toString();
  }

  public removeLevel(level: number): void {
    if (this.selectedLevels.includes(level)) {
      this.levels.push(
        this.selectedLevels.splice(this.selectedLevels.indexOf(level), 1)[0]
      );
      this.levels = this.levels.sort((a, b) => a - b);
      this.filterGroups();
    }
  }

  public addLevel(strLevel: string): void {
    const level = Number(strLevel);
    if (this.levels.includes(level)) {
      this.selectedLevels.push(
        this.levels.splice(this.levels.indexOf(level), 1)[0]
      );
      this.selectedLevels = this.selectedLevels.sort((a, b) => a - b);
      this.filterGroups();
    }
  }
}
