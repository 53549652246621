<mat-sidenav-container class="sidenav-container">
    <mat-sidenav
        #drawer
        class="sidenav"
        fixedInViewport
        [attr.role]="responsiveDesignService.isHandset() ? 'dialog' : 'navigation'"
        [mode]="responsiveDesignService.isHandset() ? 'over' : 'side'"
        [opened]="!responsiveDesignService.isHandset() && !authService.isSensorstationMember()"
    >
        <mat-toolbar>{{ 'MENU' | translate }}</mat-toolbar>
        <mat-nav-list [disabled]="this.disabled()">
            <a *ngIf="!authService.isSensorstationMember()"mat-list-item routerLink="">{{ 'HOME.TITLE' | translate }}</a>
            <!--Sensorstation BEGIN-->
            <a *ngIf="authService.isSensorstationMember()" mat-list-item routerLink="/sensorstation">{{ 'SENSORSTATION.TITLE' | translate }}</a>
            <!--Sensorstation END-->
            <a *ngIf="!authService.isSensorstationMember()" mat-list-item routerLink="/trips" id="nav-list-trips">{{ 'TRIPS.TITLE' | translate }}</a>
            <a *ngIf="authService.isAdminOrDispatcher()" mat-list-item routerLink="/admin/groups">{{ 'ADMIN.TITLE' | translate }}</a>
            <a mat-list-item [matMenuTriggerFor]="appMenu">{{ 'LANGUAGE.TITLE' | translate }}</a>
            <a mat-list-item aria-label="Toggle sidenav" *ngIf="responsiveDesignService.isHandset()" href="{{ envService.config.keycloakAccountUrl }}" ><span translate>ACCOUNT</span></a>
            <a mat-list-item *ngIf="responsiveDesignService.isHandset()" (click)="authService.logout()" routerLink="/">{{ 'LOGOUT' | translate }}</a>
        </mat-nav-list>
        <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="useLanguage('de')" translate>Deutsch</button>
            <button mat-menu-item (click)="useLanguage('en')" translate>English</button>
            <button mat-menu-item (click)="useLanguage('es')" translate>Español</button>
        </mat-menu>
    </mat-sidenav>
    <mat-sidenav-content>

        <mat-toolbar color="primary">
            <button
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()"
                    *ngIf="responsiveDesignService.isHandset()"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span>{{ titleKey | translate }}</span>
            <span *ngIf="responsiveDesignService.isHandset()" class="ts-icon"></span>
            <button
                    type="button"
                    class="account"
                    mat-icon-button
                    *ngIf = "!responsiveDesignService.isHandset()"
                    [ngClass]="{ 'sensorstation-account': authService.isSensorstationMember() }"
                    (click)="openDialog()">
                <mat-icon>account_circle</mat-icon>
            </button>
        </mat-toolbar>

        <div class="wrap">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>

    </mat-sidenav-content>
</mat-sidenav-container>