import { Injectable } from '@angular/core';

export enum CallbackEventType {
  'changed',
  'cleared',
  'show',
  'resized',
  'tripEnd',
  'newData',
  'tripChanged',
  'newTrips',
}
export type CallBackFunction = (parameter?: unknown) => unknown;
type CallBackFunctionSet = Set<CallBackFunction>;
type CallBackFunctionMap = Map<unknown, CallBackFunctionSet>;

@Injectable({
  providedIn: 'root',
})
export class ChartEventService {
  private appHeightPx: number;

  private appWidthPx: number;

  private callbackmap: Map<CallbackEventType, CallBackFunctionMap> = new Map<
    CallbackEventType,
    CallBackFunctionMap
  >();

  private getCallBackFunctionMap(
    eventType: CallbackEventType
  ): CallBackFunctionMap {
    if (!this.callbackmap.has(eventType)) {
      this.callbackmap.set(eventType, new Map<unknown, CallBackFunctionSet>());
    }
    return this.callbackmap.get(eventType);
  }

  private getCallBackFunctionSet(
    toRegister: unknown,
    eventType: CallbackEventType
  ): CallBackFunctionSet {
    const cbfm = this.getCallBackFunctionMap(eventType);
    if (!cbfm.has(toRegister)) {
      const set = new Set<CallBackFunction>();
      cbfm.set(toRegister, set);
    }
    return cbfm.get(toRegister);
  }

  public register(
    toRegister: unknown,
    eventType: CallbackEventType,
    fn: CallBackFunction
  ): void {
    this.getCallBackFunctionSet(toRegister, eventType).add(fn);
  }

  public deregister(toDeregister: unknown, eventType: CallbackEventType): void {
    this.getCallBackFunctionSet(toDeregister, eventType).clear();
  }

  public deregisterAll(toDeregister: unknown): void {
    Object.values(CallbackEventType).forEach((eventType) => {
      this.deregister(toDeregister, eventType as CallbackEventType);
    });
  }

  public emit(eventType: CallbackEventType, parameter?: unknown): void {
    this.getCallBackFunctionMap(eventType).forEach((callBackFunctionSet) => {
      if (parameter !== undefined) {
        callBackFunctionSet.forEach((callBackFunction) => {
          callBackFunction(parameter);
        });
      } else {
        callBackFunctionSet.forEach((callBackFunction) => {
          callBackFunction();
        });
      }
    });
  }

  public setViewPortPx(pixelHeight: number, pixelWidth): void {
    this.appHeightPx = pixelHeight;
    this.appWidthPx = pixelWidth;
    this.emit(CallbackEventType.resized, null);
  }

  public getAppHeightPx(reducePercent?: number): number {
    if (reducePercent) {
      return (this.appHeightPx * (100 - reducePercent)) / 100;
    }
    return this.appHeightPx;
  }

  public getAppWidthPx(reducePercent?: number): number {
    if (reducePercent) {
      return (this.appWidthPx * (100 - reducePercent)) / 100;
    }
    return this.appWidthPx;
  }
}
