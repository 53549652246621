<div class="vehicleForm" formGroupName="vehicleForm">
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.NAME"></mat-label>
    <input placeholder="{{ 'VEHICLE.NAME' | translate }}" required type="text" matInput formControlName="name" />
  </mat-form-field>

  <mat-form-field>
    <mat-label mat-label translate="USER.GROUP"></mat-label>
    <mat-select required 
      placeholder="{{ 'USER.GROUP' | translate }}"
      (selectionChange)="groupChanged($event.value.groupIdentifier)"
      formControlName="groupName"
    >
      <mat-option *ngFor="let group of groups" [value]="group">
      {{ group.groupIdentifier | convertDotGroupName }}
    </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.USEROFGROUP"></mat-label>
    <mat-select placeholder="{{ 'VEHICLE.USEROFGROUP' | translate }}" #userSelect formControlName="user" multiple>
      <mat-option #allSelected *ngIf="getUsersForGroup().length > 1" (click)="toggleAllUsersStatus(allSelected.selected)" [value]="0">{{ 'VEHICLE.ALL' | translate }}</mat-option>
      <mat-option *ngFor="let user of getUsersForGroup()" [value]="user" (click)="checkItemAllStatus()"> {{ user.userName }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.BRAND"></mat-label>
    <input placeholder="{{ 'VEHICLE.BRAND' | translate }}" required type="text" matInput formControlName="brand" />
  </mat-form-field>
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.SERIES"></mat-label>
    <input placeholder="{{ 'VEHICLE.SERIES' | translate }}" required type="text" matInput formControlName="series" />
  </mat-form-field>
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.MODEL"></mat-label>
    <input placeholder="{{ 'VEHICLE.MODEL' | translate }}" required type="text" matInput formControlName="model" />
  </mat-form-field>
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.HSN"></mat-label>
    <input placeholder="{{ 'VEHICLE.HSN' | translate }}" type="text" matInput formControlName="hsn" />
  </mat-form-field>
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.TSN"></mat-label>
    <input placeholder="{{ 'VEHICLE.TSN' | translate }}" type="text" matInput formControlName="tsn" />
  </mat-form-field>
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.AIRDRAG_COEFFICIENT"></mat-label>
    <input placeholder="{{ 'VEHICLE.AIRDRAG_COEFFICIENT' | translate }}" required type="number" matInput formControlName="airDragCoefficient"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'VEHICLE.CROSSSECTIONAREA' | translate }} in m&#0178;</mat-label>
    <input placeholder="{{ 'VEHICLE.CROSSSECTIONAREA' | translate }} in m&#0178;" required type="number" matInput formControlName="crossSectionArea"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'VEHICLE.EFFICIENCY' | translate }} in %</mat-label>
    <input placeholder="{{ 'VEHICLE.EFFICIENCY' | translate }} in %" required type="number" matInput formControlName="efficiency" matInputPercent/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'VEHICLE.FUELEMISSIONFACTOR' | translate }} in kg/l</mat-label>
    <input placeholder="{{ 'VEHICLE.FUELEMISSIONFACTOR' | translate }} in kg/l" required type="number" matInput formControlName="fuelEmissionFactor"/>
  </mat-form-field>

  <mat-form-field>  
    <mat-label mat-label translate="VEHICLE.FUELTYPE"></mat-label>
    <mat-select required 
      placeholder="{{ 'VEHICLE.FUELTYPE' | translate }}"
      formControlName="fuelValue"
    >
      <mat-option *ngFor="let ftype of selectableFuelTypes" [value]="ftype.value">
        {{ 'VEHICLE.' + ftype.key | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'VEHICLE.MASS' | translate }} in kg</mat-label>
    <input placeholder="{{ 'VEHICLE.MASS' | translate }} in kg" required type="number" matInput formControlName="mass"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.ROLLFRICTIONCOEFFICIENT"></mat-label>
    <input placeholder="{{ 'VEHICLE.ROLLFRICTIONCOEFFICIENT' | translate }}" required type="number" matInput formControlName="rollFrictionCoefficient"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label mat-label>{{ standStillFuelConsumptionLabel() }}</mat-label>
    <input placeholder="{{ standStillFuelConsumptionLabel() }}" required type="number" matInput formControlName="standStillFuelConsumption"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.WLTPCLASS"></mat-label>
    <mat-select formControlName="wltpClass" required>
      <mat-option *ngIf="form.value.vehicleForm.wltpClass === 0" [value]="0" [disabled]="true">{{ 'VEHICLE.WLTPCLASS_INVALID' | translate }}</mat-option>
      <mat-option [value]="1">{{ 'VEHICLE.WLTPCLASS_1' | translate }}</mat-option>
      <mat-option [value]="2">{{ 'VEHICLE.WLTPCLASS_2' | translate }}</mat-option>
      <mat-option [value]="3">{{ 'VEHICLE.WLTPCLASS_3' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox formControlName="airConditioning">{{ 'VEHICLE.AIRCONDITIONING' | translate }}</mat-checkbox>
  <mat-checkbox formControlName="startStop">{{ 'VEHICLE.STARTSTOP' | translate }}</mat-checkbox>
  <mat-checkbox formControlName="motorHeating">{{ 'VEHICLE.MOTORHEATING' | translate }}</mat-checkbox>
</div>
