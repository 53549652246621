import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TripResponse } from 'lcmm-lib-js';
import { InfoComponent } from '../info/info.component';
import { AuthService } from '../service/auth.service';
import { EnvConfigurationService } from '../service/env-config.service';
import { TripService } from '../service/trip.service';
import { TripMultipleDetailsComponent } from '../trips/trips-list/trip-multiple-details/trip-multiple-details.component';

export type CompareDialogComponentCloseCallback = () => void;

export interface CompareDialogComponentData {
  groupIdentifier: string;
  startDate: Date;
  endDate: Date;
  bounds: google.maps.LatLngBounds;
  cutToBounds: boolean;
}

@Component({
  selector: 'app-compare-dialog',
  templateUrl: './compare-dialog.component.html',
  styleUrls: ['./compare-dialog.component.scss'],
  standalone: false,
})
export class CompareDialogComponent {
  private static dialog: MatDialog = null;

  private static dialogRef: MatDialogRef<CompareDialogComponent> = null;

  public isLoading = false;

  constructor(
    private envService: EnvConfigurationService,
    private authService: AuthService,
    private tripService: TripService,
    @Inject(MAT_DIALOG_DATA) public data: CompareDialogComponentData
  ) {
    this.loadAndCompare();
  }

  public abort(): void {
    CompareDialogComponent.close();
  }

  public static close(): void {
    if (CompareDialogComponent.dialogRef) {
      CompareDialogComponent.dialogRef.close();
    }
  }

  public loadAndCompare(): void {
    this.isLoading = true;
    this.tripService
      .getComparableTrips(
        this.envService.config.tripsCompareMaxToCompare,
        this.data.groupIdentifier,
        this.data.startDate,
        this.data.endDate,
        this.data.bounds,
        this.data.cutToBounds
      )
      .subscribe(
        (tripResponses: TripResponse[]) => {
          this.isLoading = false;
          if (tripResponses.length <= 0) {
            InfoComponent.open(
              CompareDialogComponent.dialog,
              this.abort.bind(this),
              {
                title: 'TRIP.NOTRIPSFOUND',
                text: 'SEARCH_FAILED_TIPP',
              }
            );
          } else if (
            tripResponses.length >
            this.envService.config.tripsCompareMaxToCompare
          ) {
            InfoComponent.open(
              CompareDialogComponent.dialog,
              this.abort.bind(this),
              {
                title: 'SEARCH_TOO_MANY',
                text: 'SEARCH_TOO_MANY_TIPP',
              }
            );
          } else {
            CompareDialogComponent.dialog
              .open(TripMultipleDetailsComponent, {
                data: {
                  tripResponses,
                  isDispatcher: this.authService.isDispatcher(),
                  isAdmin: this.authService.isAdmin(),
                  showCompare:
                    tripResponses.length <=
                    this.envService.config.tripsCompareMaxToShow,
                },
                disableClose: false,
              })
              .afterClosed()
              .subscribe(() => {
                this.abort();
              });
          }
        },
        () => {
          this.isLoading = false;
          InfoComponent.open(
            CompareDialogComponent.dialog,
            this.abort.bind(this)
          );
        }
      );
  }

  public static open(
    dialog: MatDialog,
    data: CompareDialogComponentData,
    onClose?: CompareDialogComponentCloseCallback
  ): MatDialogRef<CompareDialogComponent> {
    this.dialog = dialog;
    this.dialogRef = dialog.open(CompareDialogComponent, {
      data,
      disableClose: true,
    });
    if (onClose) {
      this.dialogRef.afterClosed().subscribe(() => {
        onClose();
      });
    }
    return this.dialogRef;
  }
}
