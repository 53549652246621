import { Renderer, Cluster, ClusterStats } from '@googlemaps/markerclusterer';
import { TranslateService } from '@ngx-translate/core';
import { secondsToDuration } from '../utils';

export type MarkerPopup = (marker: google.maps.Marker, content: string) => void;

export class StandStillMarkerRenderer implements Renderer {
  private static infoWindow: google.maps.InfoWindow = null;

  private static swEurope = new google.maps.LatLng(
    46.73396554006357,
    5.117238912021516
  );

  private static neEurope = new google.maps.LatLng(
    55.42413240606426,
    16.367238912021516
  );

  public static getBoundsEurope(): google.maps.LatLngBounds {
    return new google.maps.LatLngBounds(this.swEurope, this.neEurope);
  }

  public static gooIconStart: google.maps.Icon = {
    url: 'assets/marker_start.png',
    size: new google.maps.Size(48, 48),
  };

  public static gooIconEnd: google.maps.Icon = {
    url: 'assets/marker_end.png',
    size: new google.maps.Size(48, 48),
  };

  public static gooIconRunning: google.maps.Icon = {
    url: 'assets/marker/marker_running.png',
    size: new google.maps.Size(32, 32),
    // iconAnchor: [16, 32],
    // popupAnchor: [0, -32],
  };

  public static gooIconSectionStart: google.maps.Icon = {
    url: 'assets/marker/marker_section_start.png',
    size: new google.maps.Size(32, 32),
    // iconAnchor: [16, 32],
    // popupAnchor: [0, -32],
  };

  public static gooIconSts: google.maps.Icon = {
    url: 'assets/marker/marker_sts.png',
    size: new google.maps.Size(32, 32),
    // iconAnchor: [16, 32],
    // popupAnchor: [0, -34],
  };

  public static gooIconStsFive: google.maps.Icon = {
    url: 'assets/marker/marker_sts_5.png',
    size: new google.maps.Size(32, 32),
    // iconAnchor: [16, 32],
    // popupAnchor: [0, -32],
  };

  public static gooIconStsThirty: google.maps.Icon = {
    url: 'assets/marker/marker_sts_30.png',
    size: new google.maps.Size(32, 32),
    // iconAnchor: [16, 32],
    // popupAnchor: [0, -32],
  };

  public static gooIconStsSixty: google.maps.Icon = {
    url: 'assets/marker/marker_sts_60.png',
    size: new google.maps.Size(32, 32),
    // iconAnchor: [16, 32],
    // popupAnchor: [0, -32],
  };

  public static gooIconFinished: google.maps.Icon = {
    url: 'assets/marker/marker_finished.png',
    size: new google.maps.Size(16, 16),
    // popupAnchor: [0, -8],
  };

  public static gooIconFinished8H: google.maps.Icon = {
    url: 'assets/marker/marker_finished_8h.png',
    size: new google.maps.Size(16, 16),
    // popupAnchor: [0, -8],
  };

  constructor(private ts: TranslateService) {}

  public static isInfoOpen(): boolean {
    return this.infoWindow !== null;
  }

  public static hideInfo(): void {
    if (this.isInfoOpen()) {
      this.infoWindow.close();
      this.infoWindow = null;
    }
  }

  public static showInfo(marker: google.maps.Marker, content: string): void {
    this.hideInfo();
    if (marker) {
      this.infoWindow = new google.maps.InfoWindow();
      this.infoWindow.setContent(content);
      this.infoWindow.setPosition(marker.getPosition());
      const opt: google.maps.InfoWindowOpenOptions = { map: marker.getMap() };
      this.infoWindow.open(opt);
    }
  }

  private static createStandStillIconColor(standStillTimeSec: number): string {
    if (standStillTimeSec < 30) {
      return '#8BC34A';
    }
    if (standStillTimeSec < 300) {
      return '#FFD740';
    }
    return '#FF9800';
  }

  private static createStandStillIconSvgUrl(color: string): string {
    const svg = window.btoa(`
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity="1" r="70" />
      <circle cx="120" cy="120" opacity=".3" r="90" />
      <circle cx="120" cy="120" opacity=".2" r="110" />
    </svg>`);
    return `data:image/svg+xml;base64,${svg}`;
  }

  private static createStandStillIcon(
    standStillTimeSec: number,
    sizeX: number,
    sizeY: number
  ): google.maps.Icon {
    return {
      url: this.createStandStillIconSvgUrl(
        this.createStandStillIconColor(standStillTimeSec)
      ),
      scaledSize: new google.maps.Size(sizeX, sizeY),
      anchor: new google.maps.Point(sizeX / 2, sizeY / 2),
    };
  }

  public static createStandStillMarker(
    title: string,
    standStillTimeSec: number,
    position: google.maps.LatLng,
    zIndex?: number,
    infoContent?: string
  ): google.maps.Marker {
    const m = new google.maps.Marker({
      position,
      icon: this.createStandStillIcon(standStillTimeSec, 60, 60),
      label: {
        text: secondsToDuration(standStillTimeSec),
        color: 'rgba(0,0,0,0.9)',
        fontSize: '13px',
      },
      title,
      // adjust zIndex to be above other markers
      zIndex,
    });
    if (
      infoContent !== undefined &&
      infoContent !== null &&
      infoContent.length > 0
    ) {
      m.addListener('click', () => {
        this.showInfo(m, infoContent);
      });
    }
    return m;
  }

  public static removeMarker(marker: google.maps.Marker): google.maps.Marker {
    if (marker !== null) {
      marker.setMap(null);
    }
    return marker;
  }

  public static removeMarkers(
    markers: google.maps.Marker[]
  ): google.maps.Marker[] {
    if (markers !== null && markers.length > 0) {
      markers.forEach((m) => {
        this.removeMarker(m);
      });
    }
    return [];
  }

  public static addMarker(
    map: google.maps.Map,
    marker: google.maps.Marker
  ): google.maps.Marker {
    marker.setMap(map);
    return marker;
  }

  public static replaceMarker(
    map: google.maps.Map,
    rem: google.maps.Marker,
    add: google.maps.Marker
  ): google.maps.Marker {
    this.removeMarker(rem);
    return this.addMarker(map, add);
  }

  public static createMarker(
    position: google.maps.LatLng,
    title: string,
    icon: google.maps.Icon,
    zIndex: number,
    infoContent: string,
    markerPopup?: MarkerPopup
  ): google.maps.Marker {
    const m = new google.maps.Marker({
      position,
      title,
      icon,
      zIndex,
    });
    if (infoContent !== null && infoContent.length > 0) {
      m.addListener('click', () => {
        if (markerPopup) {
          markerPopup(m, infoContent);
        } else {
          this.showInfo(m, infoContent);
        }
      });
    }
    return m;
  }

  public static createBounds(
    corner1: google.maps.LatLng,
    corner2: google.maps.LatLng
  ): google.maps.LatLngBounds {
    let latN = corner1.lat();
    let latS = corner2.lat();
    if (latS > latN) {
      latN = corner2.lat();
      latS = corner1.lat();
    }
    let lngE = corner1.lng();
    let lngW = corner2.lng();
    if (lngW > lngE) {
      lngE = corner2.lng();
      lngW = corner1.lng();
    }
    return new google.maps.LatLngBounds(
      new google.maps.LatLng(latS, lngW),
      new google.maps.LatLng(latN, lngE)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public render(cluster: Cluster, stats: ClusterStats): google.maps.Marker {
    if (cluster.markers.length === 1) {
      return cluster.markers[0];
    }
    const title = this.ts.instant('TRIP.STANDSTILL');
    let sst = 0;
    cluster.markers.forEach((m) => {
      const t = m.get('standStillTime') as number;
      sst += t;
    });
    return StandStillMarkerRenderer.createStandStillMarker(
      title,
      sst,
      cluster.position,
      sst
    );
  }
}
