import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { DateInputErrorStateMatcher } from 'src/app/utils/error-state-matcher';
import { MapHelper } from 'src/app/utils/markers/map-helper';
import { TripRoutesDirection, TripService } from 'src/app/service/trip.service';
import { AuthService } from 'src/app/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Group, TripResponse, Vehicle } from 'lcmm-lib-js';
import { UntypedFormControl } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { VehicleService } from 'src/app/service/vehicle.service';
import { sortString } from 'src/app/utils/utils';
import { map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/service/user.service';
import { ErrorComponent } from 'src/app/error/error.component';
import {
  CallbackEventType,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import { TripRoutingComponent } from '../trip-routing/trip-routing.component';

@Component({
  selector: 'app-direction-map',
  templateUrl: './direction-map.component.html',
  styleUrls: ['./direction-map.component.scss'],
  standalone: false,
  providers: [DatePipe],
})
export class DirectionMapComponent implements AfterViewInit, OnInit {
  public GROUP_IDENTIFIER_SEPARATOR: string;

  private static divIdMap = 'googleGlobalMap';

  private mapHelper: MapHelper;

  public errorStateMatcher = new DateInputErrorStateMatcher();

  public infoDialogIsOpen = false;

  public alternativesMax = 3;

  public alternativesMin = 2;

  public alternatives: number = this.alternativesMax;

  public tripDirection: TripRoutesDirection = this.createTripDirection(
    this.alternativesMax,
    null,
    null,
    null,
    null
  );

  private tripDirectionResult: TripRoutesDirection;

  public isInputDisabled = false;

  public loading = false;

  public vehicles: Vehicle[];

  public selectedVehicle?: Vehicle;

  public myControlVehicle = new UntypedFormControl();

  public filteredVehicles: Observable<Vehicle[]>;

  public groups: Group[];

  public myControlGroup = new UntypedFormControl();

  public filteredGroups: Observable<Group[]>;

  public selectedGroup?: Group;

  private userId: string = null;

  private vehicle: Vehicle = null;

  public selectVehicle(vehicle: Vehicle): void {
    this.selectedVehicle = vehicle;
    this.vehicle = vehicle;
  }

  public unselectVehicle(): void {
    this.selectedVehicle = null;
    this.myControlVehicle.reset();
  }

  private filterVehicles(vehicle: Vehicle, group: Group): Vehicle[] {
    let filteredVehicles = this.vehicles;
    if ((group && typeof group !== 'string') || vehicle) {
      filteredVehicles = filteredVehicles.filter((option) => {
        if (group && option.groupName !== group.groupIdentifier) {
          return false;
        }
        if (
          vehicle &&
          typeof vehicle === 'string' &&
          !option._name.includes((vehicle as string).toUpperCase())
        ) {
          return false;
        }
        return true;
      });
    }
    return filteredVehicles;
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    const groupVehicles = this.filterVehicles(null, group);
    if (groupVehicles.length === 1) {
      this.selectVehicle(groupVehicles[0]);
    } else {
      this.unselectVehicle();
    }
  }

  public unselectGroup(): void {
    this.selectedGroup = null;
    this.myControlGroup.reset();
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  private filterGroups(value: string): Group[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.groups.filter((group) => {
        if (group.groupName) {
          return group.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.groups;
  }

  public displayVehicle(vehicle: Vehicle): string {
    return vehicle && vehicle.name ? vehicle.name : ' --- ';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  @ViewChild(DirectionMapComponent.divIdMap, { static: false })
  gmap: ElementRef;

  constructor(
    private datePipe: DatePipe,
    private ts: TranslateService,
    public envService: EnvConfigurationService,
    private userService: UserService,
    public authService: AuthService,
    public infoDialog: MatDialog,
    private tripService: TripService,
    private vehicleService: VehicleService,
    public errorDialog: MatDialog,
    private ces: ChartEventService
  ) {
    this.GROUP_IDENTIFIER_SEPARATOR =
      envService.config.groupIdentifierSeparator;

    this.mapHelper = new MapHelper(this.ts, this.datePipe);

    this.mapHelper.registerDirectionCallback(this.directionCallback.bind(this));
  }

  async ngOnInit(): Promise<void> {
    this.vehicleService.vehicles.subscribe((vehicles) => {
      if (vehicles) {
        this.vehicles = vehicles.sort(sortString('name'));
        this.filteredVehicles = merge(
          this.myControlVehicle.valueChanges,
          this.myControlGroup.valueChanges
        ).pipe(
          startWith(''),
          map(() =>
            this.filterVehicles(
              this.myControlVehicle.value,
              this.myControlGroup.value
            )
          )
        );
      }
    });
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups;
        this.filteredGroups = this.myControlGroup.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });
    await this.authService.getUserId().then((id) => {
      this.userId = id;
    });
    /*
    if (!this.authService.isAdmin()) {
      const groupIdentifier = this.authService.getGroupIdentifier();
      console.error('##groupIdentifier?', groupIdentifier);
    }
    */
  }

  ngAfterViewInit(): void {
    this.mapHelper.initMap(this.gmap, false, true, true);
  }

  private createTripDirection(
    alternatives: number,
    startlat: number,
    startlng: number,
    endlat: number,
    endlng: number
  ): TripRoutesDirection {
    const tdp = TripService.createTripRoutesDirection(
      this.userId,
      this.vehicle,
      alternatives,
      startlat,
      startlng,
      endlat,
      endlng
    );
    return tdp;
  }

  private clearStartEnd(): void {
    this.tripDirection = this.createTripDirection(
      this.alternatives,
      null,
      null,
      null,
      null
    );
  }

  private directionCallback(direction: TripRoutesDirection): void {
    if (direction) {
      this.tripDirection = direction;
      this.updateMap();
    } else {
      this.clearStartEnd();
    }
  }

  private disableInput(): void {
    this.setBusy();
    this.disableMap(true);
  }

  private enableInput(): void {
    this.clearBusy();
    this.isInputDisabled = false;
    this.disableMap(false);
  }

  private disableMap(disable: boolean): void {
    if (disable) {
      document.getElementById('disableMapDiv').style.zIndex = '1000000000';
    } else {
      document.getElementById('disableMapDiv').style.zIndex = '-1000000000';
    }
  }

  private getNumberOrNull(text: string): number {
    if (text === null || text === undefined || Number.isNaN(text)) {
      return null;
    }
    return Number(text);
  }

  private isComError(errorCode: number): number {
    if (errorCode === 0 || (errorCode >= 400 && errorCode <= 599)) {
      return errorCode;
    }
    return null;
  }

  private isInternetLostError(errorCode: number): boolean {
    return errorCode === 0;
  }

  private isORSError(errorCode: number, errorMessage: string): boolean {
    return (
      errorCode === 400 &&
      errorMessage &&
      errorMessage.toLowerCase().includes(this.ts.instant('DIRECTION.ORS_KEY'))
    );
  }

  private parseNumberOrNull(
    text: string,
    delimiterStart: string,
    delimiterEnd: string
  ): number {
    if (text) {
      const da = text.split(delimiterStart);
      if (da.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        return this.getNumberOrNull(da[1].trim().split(delimiterEnd)[0]);
      }
    }
    return null;
  }

  // "[400 Bad R
  private getORSStatusOrNull(message: string): number {
    return this.parseNumberOrNull(message, '[', ' ');
  }

  // [{\"error\":{\"code\":2004,\"
  private getORSErrorCodeOrNull(message: string): number {
    return this.parseNumberOrNull(
      message,
      this.ts.instant('DIRECTION.ORS_CODE_KEY'),
      ','
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private showError(error: any): void {
    // default values
    let subtitle = 'ERROR.OTHER';
    let text = 'ERROR.-1';
    let hint = 'ERROR.CALL_ADMINISTRATOR';
    const stat = error.status;
    const ec = this.getNumberOrNull(stat);
    if (ec !== null) {
      // http error
      subtitle = `ERROR.${ec}`;
      if (this.isInternetLostError(ec)) {
        // no internet connection
        text = null;
        hint = 'ERROR.CHECK_INTERNET';
      } else {
        const message = error.error?.message;
        if (this.isORSError(ec, message)) {
          // ORS error response found
          hint = null;
          const orsEc = this.getORSErrorCodeOrNull(message);
          if (orsEc !== null) {
            // ors error code found in ORS response
            text = `DIRECTION.ERROR.${orsEc}`;
          } else {
            const orsStat = this.getORSStatusOrNull(message);
            if (orsStat !== null) {
              // no ors error code, but http error code found in ORS response
              text = `ERROR.${orsEc}`;
            }
          }
        }
      }
    }
    this.openError(subtitle, text, hint);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private openError(subtitle: string, text: any, hint?: string): void {
    ErrorComponent.open(
      this.errorDialog,
      null,
      {
        title: 'TOOLS.DIRECTION',
        subtitle,
        text,
        hint,
      },
      true
    );
  }

  public async createRoutes(): Promise<void> {
    this.disableInput();
    this.tripDirection.userId = this.userId;
    this.tripDirection.group = this.vehicle.groupName;
    this.tripDirection.vehicleId = this.vehicle.id;
    this.tripDirection.alternatives = this.alternatives;
    this.tripDirection.alternative_routes.target_count =
      this.tripDirection.alternatives;
    this.tripService.createRoutes(this.tripDirection).subscribe(
      (response: TripRoutesDirection) => {
        this.tripDirectionResult = response;
      },
      (error) => {
        this.showError(error);
        this.enableInput();
      },
      () => {
        this.showTripRoutingByTripId();
        this.enableInput();
        this.ces.emit(CallbackEventType.newTrips);
      }
    );
  }

  private setBusy(): void {
    this.loading = true;
    this.isInputDisabled = true;
  }

  private clearBusy(): void {
    this.loading = false;
    this.isInputDisabled = false;
  }

  public isBusy(): boolean {
    return this.loading;
  }

  public showTripRoutingByTripId(): void {
    const tripResponses: TripResponse[] = [];
    const routingMode = true;
    const hideSumAndAverage = true;
    const title = 'TRIP.ROUTINGTIPS';
    this.setBusy();
    this.tripService
      .getDetailedTrips(
        this.tripDirectionResult.group,
        this.tripDirectionResult.tripIds
      )
      .subscribe(
        (tr) => {
          tripResponses.push(tr);
        },
        (err) => {
          this.clearBusy();
          this.showError(err);
        },
        () => {
          this.clearBusy();
          this.infoDialog.open(TripRoutingComponent, {
            width: '100%',
            height: '100%',
            data: {
              tripResponses,
              title,
              routingMode,
              hideSumAndAverage:
                tripResponses.length <= 1 ? true : hideSumAndAverage,
            },
            disableClose: false,
          });
        }
      );
  }

  public disableInspectButton(): boolean {
    for (let i = 0; i < this.tripDirection.tripIds?.length; i += 1) {
      if (this.tripDirection.tripIds[i]) {
        return true;
      }
    }
    return this.isBusy() || this.tripDirection.tripIds?.length <= 0;
  }

  public coordinatesValid(): boolean {
    const cs = this.tripDirection?.startCoordinate;
    const ce = this.tripDirection?.endCoordinate;
    return (
      cs &&
      cs.lat != null &&
      cs.lon != null &&
      ce &&
      ce.lat != null &&
      ce.lon != null
    );
  }

  public updateMap(): void {
    if (this.tripDirection.alternatives < 1) {
      this.tripDirection.alternatives = this.alternativesMin;
    } else if (this.tripDirection.alternatives > this.alternativesMax) {
      this.tripDirection.alternatives = this.alternativesMax;
    }
    if (this.coordinatesValid()) {
      // If all coordinates are present, update the map
      const cs = this.tripDirection?.startCoordinate;
      const ce = this.tripDirection?.endCoordinate;
      this.mapHelper.setDirection(cs.lat, cs.lon, ce.lat, ce.lon);
    }
  }
}
