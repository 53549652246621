import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnChanges,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Trip, TripResponse } from 'lcmm-lib-js';
import { Observable, Subscription } from 'rxjs';
import { TripSectionParameter } from 'src/app/service/trip.service';
import {
  CallbackEventType,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import { TranslateService } from '@ngx-translate/core';
import { MapHelper } from 'src/app/utils/markers/map-helper';
import { tripResponseToTrip } from 'src/app/utils/utils';

@Component({
  selector: 'app-trip-map',
  templateUrl: './trip-map.component.html',
  styleUrls: ['./trip-map.component.scss'],
  standalone: false,
  providers: [DatePipe],
})
export class TripMapComponent implements AfterViewInit, OnChanges, OnDestroy {
  private static divIdMap = 'googleMap';

  private static divIdMapSection = 'googleSectionMap';

  @Input() private tripObservable: Observable<Trip>;

  @Input() public trip: Trip;

  @Input() public isSectionDialog: boolean;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  @ViewChild(TripMapComponent.divIdMap, { static: false }) gmap: ElementRef;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  @ViewChild(TripMapComponent.divIdMapSection, { static: false })
  gmapSection: ElementRef;

  public showSpinner = true;

  private mapHelper: MapHelper;

  private subscription: Subscription;

  public sectionDetailsLoading = false;

  public mapHeight: string;

  private tripsObservable: Observable<TripResponse>;

  /*
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tripResponse: TripResponse;
      isSectionDialog?: boolean;
      tripSectionParameter: TripSectionParameter;
    }
  ) {
    this.tripResponse = data.tripResponse;
    if (data.isSectionDialog !== undefined && data.isSectionDialog) {
      this.isSectionDialog = true;
      this.tripSectionParameter = data.tripSectionParameter;
    }
  }
  */

  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private ces: ChartEventService,
    private ts: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tripsObservable: Observable<TripResponse>;
    }
  ) {
    this.tripsObservable = data.tripsObservable;
    this.mapHelper = new MapHelper(this.ts, this.datePipe);
    this.mapHelper.registerSectionCallback(this.sectionEvent.bind(this));
    this.mapHelper.registerSectionClickCallback(
      this.sectionClickEvent.bind(this)
    );
    this.setMapHeight();
  }

  private setMapHeight(): void {
    const height = this.ces.getAppHeightPx(47);
    this.mapHeight = `${height}px`;
  }

  private zIndex = -1;

  private resetZIndex(): void {
    this.zIndex = -1;
  }

  private getZIndex(): number {
    this.zIndex += 1;
    return this.zIndex;
  }

  ngAfterViewInit(): void {
    if (this.isSectionDialog) {
      this.mapHelper.initMap(this.gmapSection, false);
    } else {
      this.mapHelper.initMap(this.gmap, true);
    }
    if (this.tripsObservable) {
      this.resetZIndex();
      this.tripsObservable.subscribe((trip) => {
        this.buildMapByTrip(tripResponseToTrip(trip));
      });
    } else if (this.isSectionDialog) {
      this.buildMapByTrip(this.trip);
    } else if (this.tripObservable) {
      this.subscription = this.tripObservable.subscribe((trip) => {
        this.checkTrip(trip);
      });
    }
  }

  ngOnChanges(): void {
    this.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    if (!this.isSectionDialog) {
      this.ces.emit(CallbackEventType.cleared);
    }
    this.unsubscribeTrip();
  }

  private enableSpinner(show: boolean): void {
    this.showSpinner = show;
    this.mapHelper.setEnabled(!show);
  }

  private buildMapByTrip(trip: Trip): void {
    this.enableSpinner(true);
    this.mapHelper.addTripToMap(trip, true);
    this.enableSpinner(false);
  }

  private checkTrip(trip: Trip): void {
    if (!trip || !trip.id || trip.id !== this.trip.id) {
      return;
    }
    this.trip = trip;
    this.buildMapByTrip(trip);
  }

  private unsubscribeTrip(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  private sectionEvent(tsp: TripSectionParameter): void {
    if (tsp) {
      this.ces.emit(CallbackEventType.changed, tsp);
    } else {
      this.ces.emit(CallbackEventType.cleared);
    }
  }

  private sectionClickEvent(tsp: TripSectionParameter): void {
    this.ces.emit(CallbackEventType.show, tsp);
  }
}
