<h1 mat-dialog-title>
  {{ title | translate }}
</h1>
<div mat-dialog-content>
  {{ question1 | translate }} {{ entity | translate }} {{ value }} {{ question2 | translate }} 
</div>

<div mat-dialog-actions [ngClass]="{ 'sensorstation-buttons': authService.isSensorstationMember() }">
  <button mat-raised-button [mat-dialog-close]="true" *ngIf="showActionButton">
    {{ action | translate }}
  </button>
  <button mat-raised-button [mat-dialog-close]="false">
    {{ 'CLOSE' | translate }}
  </button>
</div>
