import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { MatDialog } from '@angular/material/dialog';
import { timeoutWorker } from 'timeout-worker';
import { environment } from 'src/environments/environment';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TimeoutComponent } from './timeout/timeout.component';
import { AuthService } from './service/auth.service';
import { ChartEventService } from './service/chart-event.service';
import { ErrorComponent } from './error/error.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
  public title = environment.appTitle;

  public showNavigation = true;

  private readonly viewportChange = this.viewportRuler
    .change(200)
    .subscribe(() => this.ngZone.run(() => this.setSize()));

  constructor(
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog,
    public authService: AuthService,
    private readonly viewportRuler: ViewportRuler,
    private readonly ngZone: NgZone,
    private ces: ChartEventService,
    private titleService: Title,
    private ts: TranslateService,
    private router: Router
  ) {
    this.setSize();
  }

  ngOnInit(): void {
    // dialog timeout timer
    this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.timeoutDialog();
        this.bnIdle.stopTimer();
      }
    });
    if (this.authService.isSensorstationMember()) {
      document.body.classList.add('aiamo-theme');
      document.body.classList.remove('primary-theme');
      this.titleService.setTitle(this.ts.instant('SENSORSTATION.HEADER'));
    }
    const isForbidden = this.authService.checkForbiddenRoles();
    if (isForbidden) {
      this.showNavigation = false;
      this.showErrorDialog();
    }
  }

  ngOnDestroy(): void {
    this.viewportChange.unsubscribe();
  }

  private setSize() {
    const { height, width } = this.viewportRuler.getViewportSize();
    this.ces.setViewPortPx(height, width);
  }

  public timeoutDialog(): void {
    const dialogRef = this.dialog.open(TimeoutComponent);
    dialogRef.afterClosed().subscribe(() => {
      timeoutWorker.stop();
      this.bnIdle.startWatching(3600);
    });
  }

  private showErrorDialog(): void {
    ErrorComponent.open(
      this.dialog,
      () => {
        this.authService.logout().then(() => {
          this.showNavigation = false;
        });
      },
      {
        title: 'GUARD.ROLESMIXED',
        text: 'GUARD.TEXT',
        buttonTitle: 'GUARD.ACTION',
      }
    );
  }
}
