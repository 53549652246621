import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Group } from 'lcmm-lib-js';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-group-create-dialog',
  templateUrl: './group-create-dialog.component.html',
  styleUrls: ['./group-create-dialog.component.scss'],
  standalone: false,
})
export class GroupCreateDialogComponent implements OnInit {
  public groups: Group[];

  public group: Group = {
    groupName: '',
    parentGroupId: null,
  };

  public form: UntypedFormGroup;

  public selectedGroup: Group;

  constructor(
    public dialogRef: MatDialogRef<GroupCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { groups: Group[] },
    private fb: UntypedFormBuilder,
    public userService: UserService,
    public envService: EnvConfigurationService
  ) {
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;
    this.groups = data.groups;
    if (this.groups !== undefined && this.groups.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedGroup = this.groups[0];
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      groupName: [
        this.group.groupName,
        Validators.pattern('^[a-zA-Z0-9_\\-]*$'),
      ],
      parentGroupId: [null],
    });
  }

  public onSubmit(): void {
    if (this.selectedGroup) {
      this.form.patchValue({
        parentGroupId: this.selectedGroup.id,
      });
    }
    this.userService.createGroup(this.form.value).subscribe((createdGroup) => {
      // createdGroup.level = this.selectedGroup.level + 1;
      this.dialogRef.close(createdGroup);
    });
  }
}
