<div [ngClass]="{ 'sensorstation-footer': authService.isSensorstationMember() }">
  <mat-toolbar class="footer" [ngClass]="{ 'sensorstation-footer': authService.isSensorstationMember(), 'default-footer': !authService.isSensorstationMember() }">
    <div [ngClass]="condition()" class="btn" [routerLink]="rlImprint">{{ 'IMPRINT.TITLE' | translate }}</div>
    <div class="theme-divider">|</div>
    <div [ngClass]="condition()" class="btn" [routerLink]="rlPrivacystatement">
        <mat-icon *ngIf="!authService.isSensorstationMember()" svgIcon="privacy"></mat-icon>
      {{ 'PRIVACY.FOOTER' | translate }}
    </div>
    <div class="theme-divider">|</div>
    <div [ngClass]="condition()" class="btn" [routerLink]="rlContact">{{ 'CONTACT.TITLE' | translate }}</div>
  </mat-toolbar>
</div>
