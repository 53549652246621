import { Component, Inject } from '@angular/core';
import { GroupCode, UserService } from 'src/app/service/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';

@Component({
  selector: 'app-group-code-dialog',
  templateUrl: './group-code-dialog.component.html',
  styleUrls: ['./group-code-dialog.component.scss'],
  standalone: false,
})
export class GroupCodeDialogComponent {
  public gc: GroupCode;

  public showDone = false;

  constructor(
    public dialogRef: MatDialogRef<GroupCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupCode,
    public userService: UserService
  ) {
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;
    this.gc = data;
    this.getGroupCode();
  }

  public getGroupCode(renew?: boolean): void {
    this.userService.getGroupCode(this.gc, renew).then((gc) => {
      this.gc = gc;
    });
  }

  private copyText(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public copyCode(): void {
    if (this.gc.link) {
      this.copyText(this.gc.link);
      this.startShowDoneTime();
    }
  }

  private startShowDoneTime(): void {
    this.showDone = true;
    timer(3000).subscribe(() => {
      this.showDone = false;
    });
  }
}
