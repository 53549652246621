import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Group, Vehicle, VehicleType } from 'lcmm-lib-js';
import { FuelType, isEnumValueKnown } from 'src/app/utils/utils';

import { NetworkService } from 'src/app/service/network.service';
import { VehicleService } from '../../../service/vehicle.service';

interface IVehicleTypeGroups {
  vehicle: Vehicle;
  groups: Group[];
}

@Component({
  selector: 'app-vehicle-type-dialog',
  templateUrl: './vehicle-type-dialog.component.html',
  styleUrls: ['./vehicle-type-dialog.component.scss'],
  standalone: false,
})
export class VehicleTypeDialogComponent implements OnInit, OnDestroy {
  private className = 'VehicleTypeDialogComponent';

  public form: UntypedFormGroup;

  public vehicleType: VehicleType;

  public groups: Group[];

  private initialVehicleType: VehicleType = null;

  constructor(
    public dialogRef: MatDialogRef<VehicleTypeDialogComponent>,
    private vehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA) public data: IVehicleTypeGroups,
    public networkService: NetworkService
  ) {
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;
    this.vehicleType = data.vehicle;
    this.groups = data.groups;
    this.setInitialVehicleType(data.vehicle);
  }

  private setInitialVehicleType(value: VehicleType): void {
    this.initialVehicleType = { ...value };
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});
  }

  ngOnDestroy(): void {
    if (!this.isVehicleTypeUnchanged()) {
      this.restore();
    }
  }

  public onSubmit(): void {
    const vehicleType: VehicleType = this.form.value.vehicleTypeForm;
    vehicleType.groupName = this.vehicleType.groupName;

    this.vehicleService
      .updateVehicleType(vehicleType)
      .subscribe((updatedVehicleType) => {
        this.vehicleType = updatedVehicleType;
        this.setInitialVehicleType(updatedVehicleType);
      });
  }

  public onClose(): void {
    this.dialogRef.close(this.vehicleType);
  }

  private restore(): void {
    Object.keys(this.initialVehicleType).forEach((attribute) => {
      if (this.vehicleType[attribute] !== this.initialVehicleType[attribute]) {
        // eslint-disable-next-line no-param-reassign
        this.vehicleType[attribute] = this.initialVehicleType[attribute];
      }
    });
  }

  private compaire(vt1: VehicleType, vt2: VehicleType): boolean {
    if (vt1 === vt2) {
      return true;
    }
    if (
      vt1 === null ||
      vt1 === undefined ||
      vt2 === null ||
      vt2 === undefined
    ) {
      return false;
    }
    const attributeNames = Object.keys(vt1);
    let dc = 0;
    for (let index = 0; index < attributeNames.length; index += 1) {
      const attribute = attributeNames[index];
      if (vt1[attribute] !== vt2[attribute]) {
        dc += 1;
      }
    }
    if (dc > 0) {
      return false;
    }
    return true;
  }

  public isVehicleTypeUnchanged(): boolean {
    return (
      this.form.valid &&
      this.compaire(this.initialVehicleType, this.form.value.vehicleTypeForm)
    );
  }

  public isFuelValueOk(): boolean {
    return isEnumValueKnown(
      FuelType,
      this.form.value,
      'vehicleTypeForm.fuelValue'
    );
  }
}
