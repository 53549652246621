<h1 *ngIf="title && !doNotTranslateTitle" mat-dialog-title>{{ title | translate }}</h1>
<h1 *ngIf="title && doNotTranslateTitle" mat-dialog-title>{{ title }}</h1>
<h3 class="errorSubtitle" *ngIf="subtitle && !doNotTranslateSubtitle">{{ subtitle | translate }}</h3>
<h3 class="errorSubtitle" *ngIf="subtitle && doNotTranslateSubtitle">{{ subtitle }}</h3>
<p class="errorText" *ngIf="text && !doNotTranslateText"> {{ text | translate }}</p>
<p class="errorText" *ngIf="text && doNotTranslateText"> {{ text }}</p>
<h3 class="errorHint" *ngIf="hint && !doNotTranslateHint">{{ hint | translate }}</h3>
<h3 class="errorHint" *ngIf="hint && doNotTranslateHint">{{ hint }}</h3>
<div mat-dialog-actions class="buttons">
  <button *ngIf="!doNotTranslateButtonTitle" mat-raised-button matDialogClose="" color="primary" class="continue" class="single-button" > {{ buttonTitle | translate }}</button>
  <button *ngIf="doNotTranslateButtonTitle" mat-raised-button  matDialogClose="" color="primary" class="continue" class="single-button" > {{ buttonTitle }}</button>
</div>
