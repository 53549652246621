import { LayoutModule } from '@angular/cdk/layout';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  // provideHttpClient,
  // withInterceptorsFromDi,
} from '@angular/common/http';
import { TextFieldModule } from '@angular/cdk/text-field';

import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { RxStompService } from '@stomp/ng2-stompjs';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';

import { CookieService } from 'ngx-cookie-service';

import { GoogleMapsModule } from '@angular/google-maps';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  MatDatetimepickerModule,
  MatNativeDatetimeModule,
} from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AdministrationReportsComponent } from './administration/administration-reports/administration-reports.component';
import { AdministrationComponent } from './administration/administration.component';
import { GroupCreateDialogComponent } from './administration/lcmm-groups/group-create-dialog/group-create-dialog.component';
import { GroupCodeDialogComponent } from './administration/lcmm-groups/group-code-dialog/group-code-dialog.component';
import { LcmmGroupsComponent } from './administration/lcmm-groups/lcmm-groups.component';
import { UserCreateDialogComponent } from './administration/user/user-create-dialog/user-create-dialog.component';
import { UserComponent } from './administration/user/user.component';
import { VehicleTypeCreateDialogComponent } from './administration/vehicle-type/vehicle-type-create-dialog/vehicle-type-create-dialog.component';
import { VehicleTypeDialogComponent } from './administration/vehicle-type/vehicle-type-dialog/vehicle-type-dialog.component';
import { VehicleTypeFormComponent } from './administration/vehicle-type/vehicle-type-form/vehicle-type-form.component';
import { VehicleTypeComponent } from './administration/vehicle-type/vehicle-type.component';
import { VehicleCreateDialogComponent } from './administration/vehicles/vehicle-create-dialog/vehicle-create-dialog.component';
import { VehicleDialogComponent } from './administration/vehicles/vehicle-dialog/vehicle-dialog.component';
import { VehicleFormComponent } from './administration/vehicles/vehicle-form/vehicle-form.component';
import { VehiclesComponent } from './administration/vehicles/vehicles.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ImprintComponent } from './imprint/imprint.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { ReportsComponent } from './reports/reports.component';
import { EmailListComponent } from './system/email-list/email-list.component';
import { TripsReportsComponent } from './trips/trips-reports/trips-reports.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AuthService, authServiceInitializer } from './service/auth.service';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { ErrorComponent } from './error/error.component';
import { TripBatchResultsComponent } from './trips/trips-tools/trip-batch-results/trip-batch-results.component';

import { PrivacyRequestDialogComponent } from './privacy-request-dialog/privacy-request-dialog.component';
import { EnvConfigurationService } from './service/env-config.service';
import { TimeoutComponent } from './timeout/timeout.component';
import { getSaver, SAVER } from './utils/saver.provider';
import { MatInputPercentDirective } from './utils/mat-input-percent.directive';
import { AccountComponent } from './navigation/account/account.component';
import { MultiChartService } from './service/multi-chart.service';
import { ChartEventService } from './service/chart-event.service';
import { DownloadDialogComponent } from './download-dialog/download-dialog.component';
import { CompareDialogComponent } from './compare-dialog/compare-dialog.component';
import { ResponsiveDesignService } from './service/responsiveDesign.service';
import { TripsModule } from './trips/trips.module';
import { SharedModule } from './shared/shared.module';
import { ContactComponent } from './contact/contact.component';
import { SensorstationsComponent } from './sensorstations/sensorstations.component';
import { SensorstationsListComponent } from './sensorstations/sensorstations-list/sensorstations-list.component';
import { SensorstationCreateDialogComponent } from './sensorstations/sensorstation-create-dialog/sensorstation-create-dialog.component';
import { SensorstationFormComponent } from './sensorstations/sensorstation-form/sensorstation-form.component';
import { SensorstationDialogComponent } from './sensorstations/sensorstation-dialog/sensorstation-dialog.component';
import { SensorstationsUrlPipe } from './sensorstations/sensorstations-map/sensorstations-url.pipe';
import { SensorstationsMapComponent } from './sensorstations/sensorstations-map/sensorstations-map.component';
import { SensorstationDetailsComponent } from './sensorstations/sensorstation-details/sensorstation-details.component';
import { SensorstationDetailViewComponent } from './sensorstations/sensorstation-details/sensorstation-detail-view/sensorstation-detail-view.component';
import { SensorstationDiagramsComponent } from './sensorstations/sensorstation-details/sensorstation-diagrams/sensorstation-diagrams.component';
import { SensorstationDetailMapComponent } from './sensorstations/sensorstation-details/sensorstation-detail-map/sensorstation-detail-map.component';
import { SensorstationEstmDashboardComponent } from './sensorstations/sensorstation-estm-dashboard/sensorstation-estm-dashboard.component';
import { SensorstationAqdmDashboardComponent } from './sensorstations/sensorstation-aqdm-dashboard/sensorstation-aqdm-dashboard.component';
import { NetworkHttpInterceptor } from './service/network-http-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    ImprintComponent,
    PrivacyStatementComponent,
    ReportsComponent,
    VehicleTypeComponent,
    VehiclesComponent,
    UserComponent,
    EmailListComponent,
    NavigationComponent,
    AdministrationComponent,
    TripsReportsComponent,
    VehicleDialogComponent,
    VehicleTypeDialogComponent,
    VehicleTypeCreateDialogComponent,
    ConfirmationDialogComponent,
    DownloadDialogComponent,
    CompareDialogComponent,
    VehicleTypeFormComponent,
    VehicleFormComponent,
    VehicleCreateDialogComponent,
    AdministrationReportsComponent,
    LcmmGroupsComponent,
    GroupCreateDialogComponent,
    GroupCodeDialogComponent,
    UserCreateDialogComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    InfoComponent,
    ErrorComponent,
    TripBatchResultsComponent,
    PrivacyRequestDialogComponent,
    TimeoutComponent,
    MatInputPercentDirective,
    AccountComponent,
    ContactComponent,
    SensorstationsComponent,
    SensorstationsListComponent,
    SensorstationCreateDialogComponent,
    SensorstationFormComponent,
    SensorstationDialogComponent,
    SensorstationsMapComponent,
    SensorstationsUrlPipe,
    SensorstationDetailsComponent,
    SensorstationDetailViewComponent,
    SensorstationDiagramsComponent,
    SensorstationDetailMapComponent,
    SensorstationEstmDashboardComponent,
    SensorstationAqdmDashboardComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    LayoutModule,
    MatMenuModule,
    MatDialogModule,
    MatToolbarModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatTooltipModule,
    GoogleMapsModule,
    SharedModule,
    TripsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatChipsModule,
    MatIconModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatMomentDatetimeModule,
    MatDatepickerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: authServiceInitializer,
      multi: true,
      deps: [KeycloakService, EnvConfigurationService],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: SAVER, useFactory: getSaver },
    DatePipe,
    RxStompService,
    AuthService,
    DecimalPipe,
    CookieService,
    MultiChartService,
    ChartEventService,
    ResponsiveDesignService,
    provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkHttpInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {
  constructor(translateService: TranslateService) {
    registerLocaleData(localeDe);
    registerLocaleData(localeEn);
    registerLocaleData(localeEs);
    translateService.addLangs(['de', 'en', 'es']);
    translateService.setDefaultLang('en');
    const lang = navigator.language.substring(0, 2);
    localStorage.setItem('language', lang);
    translateService.use(lang);
  }
}
