import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sensorstation } from 'sensorstation-lib-js';

@Component({
  selector: 'app-sensorstation-details',
  templateUrl: './sensorstation-details.component.html',
  styleUrls: ['./sensorstation-details.component.scss'],
  standalone: false,
})
export class SensorstationDetailsComponent {
  public sensorstation: Sensorstation;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject(MAT_DIALOG_DATA) public sensorstationData: any) {
    // Correctly extract sensorstation from the injected data
    this.sensorstation = sensorstationData.sensorstation;
  }
}
