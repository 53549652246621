import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Group, RoleConstants } from 'lcmm-lib-js';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserService, BulkUser } from 'src/app/service/user.service';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { ErrorComponent } from 'src/app/error/error.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export interface BulkUserWithState {
  listId: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  saved: boolean;
  complete: boolean;
  // eslint-disable-next-line
  error: any;
  errorMsg: string;
  tooltip: string;
  icon: string;
}

@Component({
  selector: 'app-user-create-dialog',
  templateUrl: './user-create-dialog.component.html',
  styleUrls: ['./user-create-dialog.component.scss'],
  standalone: false,
})
export class UserCreateDialogComponent implements OnInit, AfterViewInit {
  private static maxUserListSize = 100;

  public globalStatusIcon: string = null;

  public globalStatusTooltip: string = null;

  public roles = [RoleConstants.DRIVER, RoleConstants.DISPATCHER];

  public selectedRole = null;

  public form: UntypedFormGroup;

  public selectedGroup: Group;

  public groups: Group[];

  private nextBulkUserId = 0;

  public bulkUserWithStateList: BulkUserWithState[] = [];

  public bulkUserWithStateListTable =
    new MatTableDataSource<BulkUserWithState>();

  @ViewChild(MatSort) public sort: MatSort;

  public displayedColumns: string[] = [
    'saved',
    'userName',
    'email',
    'firstName',
    'lastName',
    'removeAction',
  ];

  private createBulkUsersSubscription: Subscription = null;

  private emptyBulkUserWithState(bulkUser?: BulkUser): BulkUserWithState {
    const buws: BulkUserWithState = {
      listId: this.nextBulkUserId,
      userName: null,
      email: null,
      firstName: null,
      lastName: null,
      saved: false,
      complete: false,
      error: null,
      errorMsg: null,
      tooltip: null,
      icon: null,
    };
    this.nextBulkUserId += 1;
    if (bulkUser) {
      buws.userName = bulkUser.userName;
      buws.email = bulkUser.email;
      buws.firstName = bulkUser.firstName;
      buws.lastName = bulkUser.lastName;
      buws.saved = !!bulkUser.listId;
    }
    return buws;
  }

  constructor(
    public dialogRef: MatDialogRef<UserCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { groups: Group[] },
    private fb: UntypedFormBuilder,
    public userService: UserService,
    public envService: EnvConfigurationService,
    public errorDialog: MatDialog,
    private ts: TranslateService
  ) {
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;
    this.groups = data.groups;
    if (this.groups !== undefined) {
      // eslint-disable-next-line prefer-destructuring
      for (let i = 0; i < this.groups.length; i += 1) {
        if (
          this.groups[i].groupIdentifier === userService.getGroupIdentifier()
        ) {
          this.selectedGroup = this.groups[i];
          break;
        }
      }
    }
    this.createBulkUsersSubscription = null;
  }

  private loadForm(): void {
    if (this.bulkUserWithStateList && this.bulkUserWithStateList.length > 0) {
      this.form = this.fb.group(this.bulkUserWithStateList[0]);
    }
  }

  private loadUsers(): void {
    const buwsList: BulkUserWithState[] = [];
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      const buws = this.bulkUserWithStateList[i];
      buwsList.push(buws);
    }
    this.bulkUserWithStateListTable.data = buwsList;
  }

  ngOnInit(): void {
    this.loadUsers();
    this.loadForm();
  }

  public ngAfterViewInit(): void {
    this.bulkUserWithStateListTable.sort = this.sort;
  }

  private checkIsUserDataComplete(buws: BulkUserWithState): void {
    if (buws.error) {
      return;
    }
    const u = buws;
    u.complete = !(!u.userName || !u.email || !u.firstName || !u.lastName);
    this.setStatusIconAndTooltip(u);
  }

  private setError(buws: BulkUserWithState, error?: string): void {
    const u = buws;
    if (error) {
      u.error = error;
      u.errorMsg = this.ts.instant(error);
      if (u.errorMsg === u.error) {
        u.errorMsg = this.ts.instant('ERROR.OTHER');
      }
    } else {
      u.error = null;
      u.errorMsg = null;
    }
    this.setStatusIconAndTooltip(u);
  }

  private checkAndSetStatus(buws: BulkUserWithState, error?: string): void {
    this.setError(buws, error);
    this.checkIsUserDataComplete(buws);
    this.checkAllUserNamesUnique();
  }

  public rowChanged(buws: BulkUserWithState): void {
    const u = buws;
    u.saved = false;
    this.checkAndSetStatus(u);
  }

  private checkUsersDataByIndex(index: number): boolean {
    if (
      !this.bulkUserWithStateList ||
      index >= this.bulkUserWithStateList.length
    ) {
      return false;
    }
    this.checkAndSetStatus(this.bulkUserWithStateList[index]);
    return true;
  }

  private checkAllUserNamesUnique(): void {
    const uList = this.bulkUserWithStateList;
    for (let i = 0; i < uList.length; i += 1) {
      let notUnique = false;
      const uA = uList[i];
      if (
        uA.userName &&
        !uA.saved &&
        (!uA.error || uA.error === 'USER.CREATE.USERNAMENOTUNIQUE')
      ) {
        for (let j = 0; j < uList.length; j += 1) {
          const uB = uList[j];
          if (uB.userName) {
            notUnique =
              notUnique ||
              (uA.listId !== uB.listId &&
                uA.userName.toUpperCase() === uB.userName.toUpperCase());
          }
        }
        if (notUnique) {
          this.setError(uA, 'USER.CREATE.USERNAMENOTUNIQUE');
        } else {
          this.setError(uA);
        }
      }
    }
  }

  private checkUsersDataSequential(index?: number): void {
    const i = index !== undefined && index !== null ? index : 0;
    if (!this.checkUsersDataByIndex(i)) {
      return;
    }
    this.checkUsersDataSequential(i + 1);
  }

  private createUser(): void {
    const bul: BulkUser[] = [];
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      const buws = this.bulkUserWithStateList[i];
      if (!buws.saved && !buws.errorMsg) {
        const bu = UserService.createEmptyBulkUser();
        bu.userRole = this.selectedRole;
        bu.importMessageCode = null;
        bu.userName = buws.userName;
        bu.email = buws.email;
        bu.firstName = buws.firstName;
        bu.lastName = buws.lastName;
        bul.push(bu);
      }
    }
    if (bul.length > 0) {
      this.createBulkUsersSubscription = this.userService
        .createBulkUsers(this.selectedGroup, bul)
        .subscribe(
          (created) => {
            this.updateBulkUserWithStateList(created);
            this.createBulkUsersSubscription = null;
          },
          (err) => {
            this.setGlobalErrorToBulkUserWithStateList(JSON.stringify(err));
            this.createBulkUsersSubscription = null;
          }
        );
    }
  }

  private updateBulkUserWithState(bulkUser: BulkUser): void {
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      const buws = this.bulkUserWithStateList[i];
      if (bulkUser.userName === buws.userName) {
        const err = bulkUser.importMessageCode
          ? `USER.CREATE.${bulkUser.importMessageCode}`
          : null;
        buws.saved = !err;
        this.checkAndSetStatus(buws, err);
        return;
      }
    }
  }

  private updateBulkUserWithStateList(bulkUser: BulkUser[]): void {
    for (let i = 0; i < bulkUser.length; i += 1) {
      this.updateBulkUserWithState(bulkUser[i]);
    }
  }

  private setGlobalErrorToBulkUserWithStateList(globalError: string): void {
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      this.setError(this.bulkUserWithStateList[i], globalError);
    }
  }

  public onSubmit(): void {
    this.checkUsersDataSequential();
    this.createUser();
  }

  public cancelSubmit(): void {
    if (this.createBulkUsersSubscription) {
      this.createBulkUsersSubscription.unsubscribe();
      this.createBulkUsersSubscription = null;
    }
  }

  private getUnknownAttributes(line: string, values: string[]): string[] {
    const unknown: string[] = [];
    const attributes = line.split(',');
    for (let index = 0; index < attributes.length; index += 1) {
      const attr = attributes[index];
      if (!values.includes(attr) && !unknown.includes(attr)) {
        unknown.push(attr);
      }
    }
    return unknown.length > 0 ? unknown : null;
  }

  private getMissingAttributes(line: string, values: string[]): string[] {
    const missing: string[] = [];
    const attributes = line.split(',');
    for (let index = 0; index < values.length; index += 1) {
      const v = values[index];
      if (!attributes.includes(v) && !missing.includes(v)) {
        missing.push(v);
      }
    }
    return missing.length > 0 ? missing : null;
  }

  private getMultipleAttributes(line: string): string[] {
    const multiple: string[] = [];
    const attribs: string[] = [];
    const attributes = line.split(',');
    for (let index = 0; index < attributes.length; index += 1) {
      const attr = attributes[index];
      if (attribs.includes(attr) && !multiple.includes(attr)) {
        multiple.push(attr);
      }
      attribs.push(attr);
    }
    return multiple.length > 0 ? multiple : null;
  }

  private cleanupHeaderLine(headerline: string): string {
    let l = headerline.replace(/\s/g, '');
    while (l.startsWith('#')) {
      l = l.substring(1);
    }
    while (l.endsWith(',')) {
      l = l.substring(0, l.length - 1);
    }
    return l;
  }

  private checkHeaderLine(line: string, values: string[]): string {
    let l = line.replace(/\s/g, '');
    l = this.cleanupHeaderLine(l);
    const missingAttributes = this.getMissingAttributes(l, values);
    const unknownAttributes = this.getUnknownAttributes(l, values);
    const multipleAttributes = this.getMultipleAttributes(l);
    if (missingAttributes || unknownAttributes || multipleAttributes) {
      let err = 'CSV header wrong! attributes: ';
      err += missingAttributes ? ` missing<${missingAttributes}>` : '';
      err += unknownAttributes ? ` unknown<${unknownAttributes}>` : '';
      err += multipleAttributes ? ` multiple<${multipleAttributes}>` : '';
      return err;
    }
    return null;
  }

  private checkCsvData(csv: string, attributeCount: number): string {
    const wrongLineNumbers: number[] = [];
    const dataRows = csv.slice(csv.indexOf('\n') + 1).split('\n');
    for (let i = 0; i < dataRows.length; i += 1) {
      const row = dataRows[i];
      if (row && row.length > 0) {
        const dc = row.split(',').length;
        if (row && dc !== attributeCount) {
          wrongLineNumbers.push(i + 2);
        }
      }
    }
    return wrongLineNumbers.length > 0
      ? `CSV data! attributes count wrong in lines: ${wrongLineNumbers}`
      : null;
  }

  private getPropertyNames(headerLine: string): string[] {
    return this.cleanupHeaderLine(headerLine).split(',');
  }

  // eslint-disable-next-line
  private createError(tag1: string, tag2?: string, data?: any): Error {
    const t2 = tag2 || null;
    const d = data || '';
    const t2msg = t2 ? this.ts.instant(tag2) : '';
    const text = `${this.ts.instant(tag1)}${t2msg}${d}`;
    return new Error(text);
  }

  private importDataFromCSV(
    csvText: string,
    attributes: string[],
    removeWhitespaces: boolean
  ): Promise<BulkUser[]> {
    return new Promise<BulkUser[]>((resolve, reject) => {
      if (!csvText || csvText.length <= 0 || !csvText.includes(',')) {
        reject(new Error('CSV wrong! no content'));
      } else {
        const csv = csvText;
        const headerLine = csv.slice(0, csv.indexOf('\n'));
        const headerError = this.checkHeaderLine(headerLine, attributes);
        if (headerError) {
          reject(new Error(headerError));
        } else {
          const propertyNames: string[] = this.getPropertyNames(headerLine);
          const dataError = this.checkCsvData(csv, propertyNames.length);
          if (dataError) {
            reject(new Error(dataError));
          } else {
            const userCsvImports: BulkUser[] = [];
            const dataRows = csv.slice(csv.indexOf('\n') + 1).split('\n');
            dataRows.forEach((dataRow) => {
              let row = dataRow;
              if (removeWhitespaces) {
                row = row.replace(/\s/g, '');
              }
              if (row && row.length > 0) {
                // } && !row.startsWith('#')) {
                let valuesOk = true;
                const values = row.split(',');
                // eslint-disable-next-line
                const newCsvUser: BulkUser = UserService.createEmptyBulkUser();
                for (let index = 0; index < propertyNames.length; index += 1) {
                  const propertyName: string = propertyNames[index];
                  // eslint-disable-next-line
                  let val: any = values[index];
                  if (val === '') {
                    val = null;
                  }
                  if (val === null || val === undefined) {
                    valuesOk = false;
                  }
                  newCsvUser[propertyName] = val;
                }
                if (valuesOk) {
                  userCsvImports.push(newCsvUser);
                }
              }
            });
            if (
              userCsvImports.length > UserCreateDialogComponent.maxUserListSize
            ) {
              reject(
                this.createError(
                  'USER.CREATE.CSVTOOMANYLINES',
                  'USER.CREATE.CSVLINESMAX',
                  UserCreateDialogComponent.maxUserListSize
                )
              );
            } else {
              resolve(userCsvImports);
            }
          }
        }
      }
    });
  }

  private createBulkUserWithStateListByBulkUser(
    userCsvImports: BulkUser[]
  ): BulkUserWithState[] {
    const bulkUsers: BulkUserWithState[] = [];
    for (let i = 0; i < userCsvImports.length; i += 1) {
      bulkUsers.push(this.emptyBulkUserWithState(userCsvImports[i]));
    }
    this.checkUsersDataSequential();
    return bulkUsers;
  }

  public isSubmitting(): boolean {
    return this.createBulkUsersSubscription !== null;
  }

  public disableCloseButton(): boolean {
    return this.isSubmitting();
  }

  public disableCsvInputButton(): boolean {
    return this.bulkUserWithStateList && this.bulkUserWithStateList.length > 0;
  }

  public disableCreateButton(): boolean {
    return (
      this.isAllSaved() ||
      !this.isAllComplete() ||
      this.isSubmitting() ||
      !this.selectedGroup ||
      !this.selectedRole ||
      !this.bulkUserWithStateList ||
      this.bulkUserWithStateList.length <= 0
    );
  }

  public disableClearListButton(): boolean {
    return (
      !this.bulkUserWithStateList || this.bulkUserWithStateList.length <= 0
    );
  }

  public disableAddButton(): boolean {
    return (
      this.isSubmitting() ||
      (this.bulkUserWithStateList &&
        this.bulkUserWithStateList.length >=
          UserCreateDialogComponent.maxUserListSize)
    );
  }

  public addUser(): void {
    const buws: BulkUserWithState = this.emptyBulkUserWithState();
    this.bulkUserWithStateList.push(buws);
    this.checkUsersDataSequential();
    this.loadUsers();
  }

  // eslint-disable-next-line
  public csvInputChange(fileInputEvent: any): void {
    const fn = fileInputEvent.target.files[0];
    const reader = new FileReader();
    reader.readAsText(fn);
    // eslint-disable-next-line
    reader.onload = (event: any) => {
      const csv = event.target.result; // Content of CSV file
      this.importDataFromCSV(
        csv,
        ['userName', 'firstName', 'lastName', 'email'],
        true
      )
        .then((userCsvImports) => {
          this.bulkUserWithStateList =
            this.createBulkUserWithStateListByBulkUser(userCsvImports);
          this.checkUsersDataSequential();
          this.loadUsers();
        })
        .catch((err) => {
          this.openError(err);
        });
    };
  }

  private openError(err: string): void {
    ErrorComponent.open(
      this.errorDialog,
      null,
      {
        title: 'USER.CSVFILE',
        text: err,
        doNotTranslateText: true,
      },
      true
    );
  }

  private deleteBulkUserFromList(bulkUser: BulkUser): void {
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      const buws = this.bulkUserWithStateList[i];
      if (buws.listId === bulkUser.listId) {
        this.bulkUserWithStateList.splice(i, 1);
      }
    }
  }

  public removeItem(bulkUser: BulkUser): void {
    if (!bulkUser) {
      this.bulkUserWithStateList = [];
    } else {
      this.deleteBulkUserFromList(bulkUser);
    }
    this.loadUsers();
    this.setGlobalStatusIconAndTooltip();
  }

  private countSaveErrors(): number {
    if (this.bulkUserWithStateList.length <= 0) {
      return 0;
    }
    let ec = 0;
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      const bu = this.bulkUserWithStateList[i];
      if (bu.error) {
        ec += 1;
      }
    }
    return ec;
  }

  private countSaved(): number {
    if (this.bulkUserWithStateList.length <= 0) {
      return 0;
    }
    let saved = 0;
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      const buws = this.bulkUserWithStateList[i];
      if (buws.saved) {
        saved += 1;
      }
    }
    return saved;
  }

  private isAllSaved(): boolean {
    if (this.bulkUserWithStateList.length <= 0) {
      return false;
    }
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      const buws = this.bulkUserWithStateList[i];
      if (!buws.saved) {
        return false;
      }
    }
    return true;
  }

  private isAllComplete(): boolean {
    if (this.bulkUserWithStateList.length <= 0) {
      return false;
    }
    for (let i = 0; i < this.bulkUserWithStateList.length; i += 1) {
      const buws = this.bulkUserWithStateList[i];
      if (!buws.saved && !buws.complete) {
        return false;
      }
    }
    return true;
  }

  private setStatusIconAndTooltip(bulkUser: BulkUserWithState): void {
    const u = bulkUser;
    if (u.saved) {
      u.icon = 'done';
      u.tooltip = this.ts.instant('USER.CREATE.SAVED');
    } else if (!u.complete) {
      u.icon = 'error';
      u.tooltip = this.ts.instant('USER.CREATE.DATAERROR');
    } else if (u.error) {
      u.icon = 'error';
      u.tooltip = u.errorMsg;
    } else {
      u.icon = null;
      u.tooltip = null;
    }
    this.setGlobalStatusIconAndTooltip();
  }

  private setGlobalStatusIconAndTooltip(): void {
    this.globalStatusIcon = null;
    this.globalStatusTooltip = null;
    if (this.bulkUserWithStateList.length > 0) {
      const ec = this.countSaveErrors();
      if (ec) {
        this.globalStatusIcon = 'error';
        this.globalStatusTooltip = `${ec} ${this.ts.instant(
          'USER.CREATE.ERROR'
        )} / ${this.countSaved()} ${this.ts.instant('USER.CREATE.SAVED')}`;
      } else if (this.isAllSaved()) {
        this.globalStatusIcon = 'done_all';
        this.globalStatusTooltip = `${
          this.bulkUserWithStateList.length
        } ${this.ts.instant('USER.CREATE.SAVED')}`;
      }
    }
  }
}
