<app-overlay-spinner *ngIf="networkService.offlineOrLoading || !users"></app-overlay-spinner>

<div class="filter">

  <mat-form-field>
    <mat-label mat-label translate="USER.ROLE"></mat-label>
    <mat-select matInput [(value)]="selectedRole" (selectionChange)="queryUsers()">
      <mat-option *ngFor="let selectedRole of roles" [value]="selectedRole">{{ 'ROLE.' + selectedRole  | translate }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field hideRequiredMarker="false">
    <mat-label>{{ 'TRIP.GROUPNAME' | translate }}</mat-label>
    <mat-chip-grid #chipList2 aria-label="Group selection">
      <mat-chip-row class="groups-mat-chip"
        *ngIf="selectedGroup"
        (removed)="removeGroup()"
      >
        {{selectedGroup.groupName}}
        <mat-icon matChipRemove >cancel</mat-icon>
      </mat-chip-row>
      <input *ngIf="!selectedGroup"
      type="text"
      placeholder
      required
      matInput
      [formControl]="groupFilter"
      [matAutocomplete]="auto2"
      [matChipInputFor]="chipList2"
    />
    </mat-chip-grid>
    <mat-autocomplete
      #auto2="matAutocomplete"
      (optionSelected)="selectGroup($event.option.value)"
      [displayWith]="displayGroup"
    >
      <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
        <span>
           {{ group.groupIdentifier | convertDotGroupName }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'USER.USERNAME' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryUsers()" (blur)="queryUsers()" [(ngModel)]="userNameFilter"/>
    <button mat-button *ngIf="userNameFilter" matSuffix mat-icon-button aria-label="Clear" (click)="userNameFilter=''; queryUsers()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'USER.FIRSTNAME' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryUsers()" (blur)="queryUsers()" [(ngModel)]="firstNameFilter"/>
    <button mat-button *ngIf="firstNameFilter" matSuffix mat-icon-button aria-label="Clear" (click)="firstNameFilter=''; queryUsers()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'USER.LASTNAME' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryUsers()" (blur)="queryUsers()" [(ngModel)]="lastNameFilter"/>
    <button mat-button *ngIf="lastNameFilter" matSuffix mat-icon-button aria-label="Clear" (click)="lastNameFilter=''; queryUsers()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'USER.EMAIL' | translate }}</mat-label>
    <input matInput (keyup.enter)="queryUsers()" (blur)="queryUsers()" [(ngModel)]="emailFilter"/>
    <button mat-button *ngIf="emailFilter" matSuffix mat-icon-button aria-label="Clear" (click)="emailFilter=''; queryUsers()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

</div>

<button mat-raised-button (click)="create()">
  {{ 'USER.TITLECREATE' | translate }}
</button>

<table class="test" matSort mat-table [dataSource]="usersListTable" class="mat-elevation-z8">
  <ng-container matColumnDef="userName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'USER.USERNAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'USER.FIRSTNAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.firstName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'USER.LASTNAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.lastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'USER.EMAIL' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.email }}
    </td>
  </ng-container>

  <ng-container matColumnDef="userRole">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'USER.ROLE' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ 'ROLE.' + element.userRole  | translate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="group">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'USER.GROUP' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.attributes.groupIdentifier | convertDotGroupName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef translate="DELETE"></th>
    <td mat-cell *matCellDef="let element; let i = index">
      <button mat-raised-button (click)="delete(element)">
        {{ 'DELETE' | translate }}
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator class="mat-elevation-z8"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="resetPage($event.pageSize)">
</mat-paginator>
