import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/auth.service';
import { LcmmDataSource } from 'src/app/datasource/lcmm-data-source';
import { Sort } from 'src/app/datasource/page';
import { Sensorstation } from 'sensorstation-lib-js';
import { Group } from 'lcmm-lib-js';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogEntity,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { NavigationComponent } from 'src/app/navigation/navigation.component';
import { FooterComponent } from 'src/app/footer/footer.component';
import { ResponsiveDesignService } from 'src/app/service/responsiveDesign.service';
import {
  NetworkService,
  NetworkStateEvent,
} from 'src/app/service/network.service';
import { MatPaginator } from '@angular/material/paginator';
import {
  SensorstationQuery,
  SensorstationService,
} from '../service/sensorstation.service';
import { SensorstationCreateDialogComponent } from '../sensorstation-create-dialog/sensorstation-create-dialog.component';
import { SensorstationDialogComponent } from '../sensorstation-dialog/sensorstation-dialog.component';
import { SensorstationDetailsComponent } from '../sensorstation-details/sensorstation-details.component';

@Component({
  selector: 'app-sensorstations-list',
  templateUrl: './sensorstations-list.component.html',
  styleUrls: ['./sensorstations-list.component.scss'],
  standalone: false,
})
export class SensorstationsListComponent implements OnInit, OnDestroy {
  private className = 'SensorstationsListComponent';

  public displayedColumns: string[] = [
    'sensorstationName',
    'sensorstationId',
    'sensorstationGroup',
    'streetName',
    'cityName',
    'details',
  ];

  public groups: Group[] = [];

  public filteredGroups: Observable<Group[]>;

  public groupFilter = '';

  public selectedGroup: Group;

  private currentlyDisplayedSensorstation: Sensorstation;

  public initialSort: Sort = { property: 'deviceId', order: 'asc' };

  public sensorstationData: LcmmDataSource<Sensorstation, SensorstationQuery>;

  public deviceIdFilter = '';

  public deviceNameFilter = '';

  private originalOwner = 'TELEKOM';

  @ViewChild(MatSort) public sort: MatSort;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public sensorstationService: SensorstationService,
    private cdr: ChangeDetectorRef,
    private responsiveDesignService: ResponsiveDesignService,
    public networkService: NetworkService
  ) {
    this.sensorstationData = new LcmmDataSource<
      Sensorstation,
      SensorstationQuery
    >(
      'Sensorstation',
      (request, query) => this.sensorstationService.page(request, query),
      this.initialSort,
      {
        deviceId: undefined,
        aFESerialNumber: undefined,
        name: undefined,
        group: undefined,
        street: undefined,
        city: undefined,
        state: undefined,
      },
      this.networkService
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  private nsCallback(eventType: NetworkStateEvent, msg?: any): void {
    switch (eventType) {
      case NetworkStateEvent.CLIENTERROR:
      case NetworkStateEvent.SERVERERROR:
        this.paginator.pageIndex -= 1;
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.networkService.register(this.className, this.nsCallback.bind(this));
  }

  ngOnDestroy(): void {
    this.networkService.deregister(this.className);
    NavigationComponent.setDisabled(false);
    FooterComponent.setDisabled(false);
    this.responsiveDesignService.deregister(this.className);
  }

  public openEdit(
    sensorstation: Sensorstation,
    allowEditing: boolean,
    event?: MouseEvent
  ): void {
    if (event) {
      event.stopPropagation();
    }
    const dialogRef = this.dialog.open(SensorstationDialogComponent, {
      width: '80%',
      data: { sensorstation, groups: this.groups, allowEditing },
    });

    dialogRef.afterClosed().subscribe((isChanged) => {
      if (isChanged != null && isChanged === true) {
        this.sensorstationData.fetch();
      }
    });
  }

  public create(): void {
    const dialogRef = this.dialog.open(SensorstationCreateDialogComponent, {
      width: '80%',
      height: '80%',
      data: {
        groups: this.groups,
      },
    });

    dialogRef.afterClosed().subscribe((returnedSensorstation) => {
      if (returnedSensorstation != null) {
        this.sensorstationData.fetch();
      }
    });
  }

  private alterSensorstation(
    theSensorstation: Sensorstation,
    theAction: ConfirmationDialogAction,
    func: (s: Sensorstation) => Observable<Sensorstation>
  ) {
    const dialogData: ConfirmationDialogData = {
      entity: ConfirmationDialogEntity.sensorstation,
      action: theAction,
      data: theSensorstation.name,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((shoudlAlter) => {
      if (shoudlAlter) {
        func(theSensorstation).subscribe(() => {
          this.sensorstationData.fetch();
        });
      }
    });
  }

  public deleteSensorstation(
    sensorstation: Sensorstation,
    event?: MouseEvent
  ): void {
    if (event) {
      event.stopPropagation();
    }
    const alterFunction = this.sensorstationService.deleteSensorstation.bind(
      this.sensorstationService
    );
    this.alterSensorstation(
      sensorstation,
      ConfirmationDialogAction.delete,
      alterFunction
    );
  }

  public isOwner(sensorstation: Sensorstation): boolean {
    return sensorstation.owner === this.originalOwner;
  }

  public showDetails(sensorstation: Sensorstation): void {
    this.currentlyDisplayedSensorstation = sensorstation;
    const dialogRef = this.dialog.open(SensorstationDetailsComponent, {
      width: '80%',
      height: '80%',
      data: {
        sensorstation,
      },
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  public queryByFilter(): void {
    this.sensorstationData.queryBy({
      name: this.deviceNameFilter,
      deviceId: this.deviceIdFilter,
      group: this.groupFilter,
    });
  }
}
