import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-sensorstations-map',
  templateUrl: './sensorstations-map.component.html',
  styleUrls: ['./sensorstations-map.component.scss'],
  standalone: false,
  providers: [DatePipe],
})
export class SensorstationsMapComponent implements OnInit, OnDestroy {
  public url: string;

  private resizeListener: () => void;

  constructor(
    public envService: EnvConfigurationService,
    private elementRef: ElementRef
  ) {
    this.url = `${envService.config.sensorstationMap}`;
  }

  // Method to change the URL dynamically
  public changeUrl(newUrl: string) {
    this.url = newUrl;
  }

  ngOnInit(): void {
    this.setIframeHeight();
    this.resizeListener = this.updateIframeHeight.bind(this);
    window.addEventListener('resize', this.resizeListener);
  }

  private setIframeHeight(): void {
    const iframe = this.elementRef.nativeElement.querySelector(
      '#sensorstation-map'
    ) as HTMLIFrameElement;
    if (iframe) {
      this.updateIframeHeight();
    }
  }

  private updateIframeHeight(): void {
    const iframe = this.elementRef.nativeElement.querySelector(
      '#sensorstation-map'
    ) as HTMLIFrameElement;
    if (iframe) {
      const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      iframe.style.height = `${screenHeight - 185}px`;
    }
  }

  ngOnDestroy(): void {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }
}
