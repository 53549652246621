import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CalculatedPosition, Trip } from 'lcmm-lib-js';
import { ChartEventService } from 'src/app/service/chart-event.service';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import {
  AbstractChartComponent,
  ExtendedPoint,
} from '../abstract-chart/abstract-chart.component';

@Component({
  selector: 'app-trip-co2-profile',
  templateUrl: '../abstract-chart/abstract-chart.component.html',
  styleUrls: ['../abstract-chart/abstract-chart.component.scss'],
  standalone: false,
})
export class TripCo2ProfileComponent extends AbstractChartComponent {
  private configService: EnvConfigurationService;

  constructor(
    translateService: TranslateService,
    configService: EnvConfigurationService,
    dialog: MatDialog,
    ces: ChartEventService
  ) {
    super(translateService, dialog, ces);
    this.yLabel = 'TRIPS.CO2.YLABEL';
    this.chartType = 'linear';
    this.configService = configService;
  }

  // provides position co2 data per km aggregated into chunks of config.co2Distance m
  // eslint-disable-next-line class-methods-use-this
  public getData(positions: CalculatedPosition[], trip: Trip): ExtendedPoint[] {
    const chartPoints: ExtendedPoint[] = [];
    if (trip.absoluteCalculation) {
      // distance for the considered window
      let distance = 0;
      let co2 = 0;
      let counter = 0;
      // total distance passed so far
      let distanceSum = 0;
      const maxCo2 =
        ((trip.absoluteCalculation.co2Emission * 1000) /
          trip.calculation.distance) *
        this.configService.config.co2LimitMultiplier;
      positions.forEach((p) => {
        if (p.speed > 0) {
          if (p.distance) {
            distance += p.distance;
            distanceSum += p.distance;
          }
          if (p.co2 > 0) {
            co2 += p.co2;
            if (distanceSum / this.configService.config.co2Distance > counter) {
              const emissions = Math.round((co2 * 1000 * 1000) / distance);
              chartPoints.push({
                x: distanceSum / 1000,
                y: emissions < maxCo2 ? emissions : maxCo2,
                t: new Date(p.dtime),
              });
              counter += 1;
              co2 = 0;
              distance = 0;
            }
          }
        }
      });
    }
    return chartPoints;
  }
}
