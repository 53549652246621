import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-sensorstation-aqdm-dashboard',
  templateUrl: './sensorstation-aqdm-dashboard.component.html',
  styleUrl: './sensorstation-aqdm-dashboard.component.scss',
  standalone: false,
})
export class SensorstationAqdmDashboardComponent implements OnInit, OnDestroy {
  public url1: string;

  public url2: string;

  private resizeListener: () => void;

  constructor(
    public envService: EnvConfigurationService,
    private elementRef: ElementRef
  ) {
    const currentTime = new Date();

    // Adjust to the hour before the last full hour
    const referenceTime = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      currentTime.getHours() - 2, // Subtract 2 hours to get the full hour before the last one
      0,
      0,
      0 // Reset minutes, seconds, and milliseconds
    );

    const from = referenceTime.getTime();
    const to = from + 3600000;

    this.url1 = `${envService.config.sensorstationAQDM}&panelId=4&from=${from}&to=${to}`;
    this.url2 = `${envService.config.sensorstationAQDM}&panelId=2&from=${from}&to=${to}`;
  }

  // Method to change the URL dynamically
  public changeUrl1(newUrl: string) {
    this.url1 = newUrl;
  }

  public changeUrl2(newUrl: string) {
    this.url2 = newUrl;
  }

  ngOnInit(): void {
    this.setIframeHeights();
    this.resizeListener = this.updateIframeHeights.bind(this);
    window.addEventListener('resize', this.resizeListener);
  }

  private setIframeHeights(): void {
    this.updateIframeHeights();
  }

  private updateIframeHeights(): void {
    const iframe1 = this.elementRef.nativeElement.querySelector(
      '#sensorstation-aqdm-1'
    ) as HTMLIFrameElement;
    const iframe2 = this.elementRef.nativeElement.querySelector(
      '#sensorstation-aqdm-2'
    ) as HTMLIFrameElement;

    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    const totalOffset = 185;
    const availableHeight = screenHeight - totalOffset;

    if (iframe1 && iframe2) {
      const fixedHeight = 65;
      iframe1.style.height = `${fixedHeight}px`;
      iframe2.style.height = `${availableHeight - fixedHeight}px`;
    }
  }

  ngOnDestroy(): void {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }
}
