<mat-grid-list [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)">
  <mat-grid-tile *ngFor="let detail of detailsListTrip" class="gridTile">
    <div class="info_element">
      <div class="label" translate="{{ detail.label }}"></div>
      <div class="data">
        <span *ngIf="detail.value != null">{{ detail.value }}</span>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<div *ngIf="showStarsAi">
  <mat-grid-list class="addDistance" [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)" id="trip-details-stars-ai">
    <mat-grid-tile>
      <div class="info_element">
        <div class="label" translate="TRIP.ROUTEEVALUATION"></div>
        <div>
          <mat-icon *ngFor="let dummy of [].constructor(5); let i = index" class="material-icon-outlined" id="star">
            <span class="span-trip-star-ai">{{ (i + 1) <= routeStarsAi ? 'star' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="info_element">
        <div class="label" translate="TRIP.TRAFFICEVALUATION"></div>
        <div>
          <mat-icon *ngFor="let dummy of [].constructor(5); let i = index" class="material-icon-outlined" id="star">
            <span class="span-trip-star-ai">{{ (i + 1) <= trafficStarsAi ? 'star' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="info_element">
        <div class="label" translate="TRIP.DRIVINGEVALUATION"></div>
        <div>
          <mat-icon *ngFor="let dummy of [].constructor(5); let i = index" class="material-icon-outlined" id="star">
            <span class="span-trip-star-ai">{{ (i + 1) <= drivingStarsAi ? 'star' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="!isTripValidAi">
      <div class="info_element">
        <div class="trip-ai-not-valid" translate="TRIP.ISNOTVALID"></div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<div *ngIf="showStars">
  <mat-grid-list [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)" id="trip-details-stars">
    <mat-grid-tile>
      <div class="info_element">
        <div>
          <mat-icon *ngFor="let dummy of [].constructor(5); let i = index" id="starNoAi">
            <span class="span-trip-star">{{ (i + 1) <= routeStars ? 'starNoAi' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="info_element">
        <div>
          <mat-icon *ngFor="let dummy of [].constructor(5); let i = index" id="starNoAi">
            <span class="span-trip-star">{{ (i + 1) <= trafficStars ? 'starNoAi' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="info_element">
        <div>
          <mat-icon *ngFor="let dummy of [].constructor(5); let i = index" id="starNoAi">
            <span class="span-trip-star">{{ (i + 1) <= drivingStars ? 'starNoAi' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<mat-grid-list [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)">
  <mat-grid-tile *ngFor="let detail of detailsListCalculation" class="gridTile">
    <div class="info_element">
      <div class="label" translate="{{ detail.label }}"></div>
      <div class="data">
        <span *ngIf="detail.value">
          <ng-container *ngIf="detail.value.includes('/') else normalValue">
            {{ detail.value.substring(0, detail.value.lastIndexOf('/') + 1) }}
            <span [ngClass]="detail.colorClass">
              {{ detail.value.substring(detail.value.lastIndexOf('/') + 1) }}
            </span>
          </ng-container>
          <ng-template #normalValue>
            {{ detail.value }}
          </ng-template>
        </span>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<mat-grid-list [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)" class="addDistance">
  <mat-grid-tile *ngFor="let detail of detailsListVehicle" class="gridTile">
    <div class="info_element">
      <div class="label" translate="{{ detail.label }}"></div>
      <div class="data">
        <span *ngIf="detail.value != null" [innerHTML]="detail.value | safeHtml"></span>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngFor="let detail of detailsListVehicleCheckbox" class="gridTile">
    <div class="info_element">
      <div class="label" translate="{{ detail.label }}"></div>
      <mat-checkbox class="detailsCheckbox" [(ngModel)]="detail.value" [disabled]="true" *ngIf="detail.value != undefined"> </mat-checkbox>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<div class="single-button" *ngIf="tripResponse.vehicle.originalVehicleId && (isDispatcher || isAdmin)">
  <button mat-raised-button (click)="recalculate()">
    {{ 'TRIP.RECALCULATE' | translate }}
  </button>
</div>