<mat-card appearance="outlined" class="card">

  <div class="grafana-container" style="text-align: center;">
    <iframe id="sensorstation-map"
    [src]="url | sensorstationsUrl"
    width="100%" height="100%" frameborder="0" style="border:none;"
    ></iframe>
  </div>
</mat-card>


