import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { InfoComponent } from '../info/info.component';
import {
  Download,
  DownloadState,
  DownloadStatus,
  DownloadType,
} from '../service/download.service';
import { TripService } from '../service/trip.service';

export type DownloadDialogComponentCloseCallback = () => void;

export interface DownloadDialogComponentData {
  groupIdentifier: string;
  startDate: Date;
  endDate: Date;
  bounds: google.maps.LatLngBounds;
  cutToBounds: boolean;
}

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss'],
  standalone: false,
})
export class DownloadDialogComponent {
  private static dialog: MatDialog = null;

  private static dialogRef: MatDialogRef<DownloadDialogComponent> = null;

  private downloadData: DownloadDialogComponentData = null;

  private downloadFile$: Observable<Download>;

  public loading: Map<DownloadType, DownloadStatus> = new Map<
    DownloadType,
    DownloadStatus
  >();

  constructor(
    private tripService: TripService,
    @Inject(MAT_DIALOG_DATA) public data: DownloadDialogComponentData
  ) {
    this.downloadData = data;
  }

  private _getLoading(type: DownloadType): DownloadStatus {
    if (!this.loading.has(type)) {
      const ds: DownloadStatus = {
        type,
        loading: false,
        progress: 0,
        ok: true,
        loaded: false,
      };
      this.loading.set(type, ds);
    }
    return this.loading.get(type);
  }

  private _isLoading(type: DownloadType): boolean {
    return this._getLoading(type).loading;
  }

  public isLoadingCsv(): boolean {
    return this._isLoading(DownloadType.csv);
  }

  public isLoadingZip(): boolean {
    return this._isLoading(DownloadType.zip);
  }

  private _getLoadingIcon(type: DownloadType): string {
    const l = this._getLoading(type);
    let iconName = '';
    if (l.loaded) {
      if (l.ok) {
        iconName = 'done';
      } else {
        iconName = 'remove';
      }
    }
    return iconName;
  }

  public getLoadingIconCsv(): string {
    return this._getLoadingIcon(DownloadType.csv);
  }

  public getLoadingIconZip(): string {
    return this._getLoadingIcon(DownloadType.zip);
  }

  private _setDownloadProgress(type: DownloadType, progress: number): void {
    this._getLoading(type).progress = progress;
  }

  private _getDownloadProgress(type: DownloadType): number {
    return this._getLoading(type).progress;
  }

  public getLoadingProgressCsv(): number {
    return this._getDownloadProgress(DownloadType.csv);
  }

  public getLoadingProgressZip(): number {
    return this._getDownloadProgress(DownloadType.zip);
  }

  private setLoading(type: DownloadType): void {
    const l = this._getLoading(type);
    l.loading = true;
    l.ok = true;
    l.loaded = false;
  }

  private unsetLoading(type: DownloadType, state: boolean): void {
    const l = this._getLoading(type);
    l.loading = false;
    l.ok = state;
    l.loaded = state;
  }

  private downloadTrips(type: DownloadType): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.downloadFile$ = this.tripService.getTrips(
        type,
        this.downloadData.groupIdentifier,
        this.downloadData.startDate,
        this.downloadData.endDate,
        this.downloadData.bounds,
        this.downloadData.cutToBounds
      );
      if (this.downloadFile$) {
        let state = false;
        this.downloadFile$.subscribe(
          (download) => {
            if (download.state === DownloadState.DONE) {
              state = true;
              this._setDownloadProgress(type, download.progress);
            }
          },
          () => {
            this.unsetLoading(type, state);
            resolve(state);
          },
          () => {
            this.unsetLoading(type, state);
            resolve(state);
          }
        );
      }
    });
  }

  public downloadOverviewCsv(): void {
    this.setLoading(DownloadType.csv);
    this.downloadTrips(DownloadType.csv).then((isDownloaded) => {
      if (!isDownloaded) {
        InfoComponent.open(
          DownloadDialogComponent.dialog,
          this.abort.bind(this),
          {
            title: 'TRIP.NOTRIPSFOUND',
            text: 'SEARCH_FAILED_TIPP',
          }
        );
      }
    });
  }

  public downloadTripsZip(): void {
    this.setLoading(DownloadType.zip);
    this.downloadTrips(DownloadType.zip).then((isDownloaded) => {
      if (!isDownloaded) {
        InfoComponent.open(
          DownloadDialogComponent.dialog,
          this.abort.bind(this),
          {
            title: 'TRIP.NOTRIPSFOUND',
            text: 'SEARCH_FAILED_TIPP',
          }
        );
      }
    });
  }

  public abort(): void {
    DownloadDialogComponent.close();
  }

  public static close(): void {
    if (DownloadDialogComponent.dialogRef) {
      DownloadDialogComponent.dialogRef.close();
    }
  }

  public static open(
    dialog: MatDialog,
    data: DownloadDialogComponentData,
    onClose?: DownloadDialogComponentCloseCallback
  ): MatDialogRef<DownloadDialogComponent> {
    this.dialog = dialog;
    this.dialogRef = dialog.open(DownloadDialogComponent, {
      data,
      disableClose: true,
    });
    if (onClose) {
      this.dialogRef.afterClosed().subscribe(() => {
        onClose();
      });
    }
    return this.dialogRef;
  }
}
