import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export enum Environment {
  Prod = 'prod',
  Staging = 'staging',
  Test = 'test',
  Dev = 'dev',
  Local = 'local',
}

export interface Configuration {
  keyCloak: boolean;
  tripManagementUrl: string;
  tripManagementUrlWs: string;
  userManagementUrl: string;
  appDownloadUrl: string;
  groupIdentifierSeparator: string;
  sensorstationManagementUrl: string;
  grafanaUrl: string;
  sensorstationMap: string;
  sensorstationESTM: string;
  sensorstationAQDM: string;

  showEurope: boolean;
  keycloakAccountUrl: string;
  keycloakAuthUrl: string;
  keycloakClientId: string;
  keycloakRealm: string;

  // the host to register users by registration code
  lcmmUrl: string;

  // multiplier for upper limit for co2 emission values
  co2LimitMultiplier: number;
  // distance for aggregating co2 emissions
  co2Distance: number;

  standStillDistance: number;
  standStillLimitMultiplier: number;

  tripsCompareMaxToCompare: number;
  tripsCompareMaxToShow: number;
}

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  private readonly configUrl = 'assets/config/config.json';

  private configData: Configuration;

  constructor(private http: HttpClient) {}

  public async load(): Promise<void> {
    const result = await this.http
      .get<Configuration>(`${this.configUrl}`)
      .toPromise();
    this.configData = result;
  }

  public get config(): Configuration {
    return this.configData;
  }
}
