import { Component } from '@angular/core';
import {
  Download,
  DownloadService,
  DownloadState,
} from '../service/download.service';
import { EnvConfigurationService } from '../service/env-config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: false,
})
export class HomeComponent {
  public loading = false;

  constructor(
    public envService: EnvConfigurationService,
    private downloadService: DownloadService
  ) {}

  public appDownload(): void {
    this.loading = true;
    this.downloadService
      .downloadFile(this.envService.config.appDownloadUrl, (event) => {
        const contentDisposition = event.headers.get('Content-Disposition');
        if (!contentDisposition) {
          return 'lcmm-app.apk';
        }
        const regex = /filename=(.*)/;
        return contentDisposition.match(regex)[1];
      })
      .subscribe((download: Download) => {
        if (download.state === DownloadState.DONE) {
          this.loading = false;
        }
      });
  }
}
