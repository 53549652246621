<app-overlay-spinner *ngIf="mapHelper.isBusy()"></app-overlay-spinner>
<div class="row" *ngIf="!mapHelper.isBusy()">
    <div class="col">
        <app-trip-multiple-details [mapHelper]="mapHelper" [title]="title" [routingMode]="routingMode" [hideSumAndAverage]="hideSumAndAverage"></app-trip-multiple-details>
        <button mat-raised-button color="primary" class="compare-close-button" [mat-dialog-close]>
            {{ 'CLOSE' | translate }}
        </button>
    </div>
    <div class="col compare-map" #googleRoutingMap></div>
</div>
<label *ngIf="errorMsg !== null" translate>
    {{ errorMsg }}
</label>
