import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Sensorstation } from 'sensorstation-lib-js';

@Component({
  selector: 'app-sensorstation-detail-view',
  templateUrl: './sensorstation-detail-view.component.html',
  styleUrls: ['./sensorstation-detail-view.component.scss'],
  standalone: false,
})
export class SensorstationDetailViewComponent implements OnInit {
  public detailsListSensorstation: {
    label: string;
    value: string | number;
  }[];

  public breakpoint: number;

  @Input() public sensorstation: Sensorstation;

  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  constructor(private ts: TranslateService) {}

  ngOnInit(): void {
    this.computeDetails();
  }

  private computeDetails(): void {
    this.setDetailsList();
    this.breakpoint = 1;
  }

  private setDetailsList() {
    this.detailsListSensorstation = [];

    if (this.sensorstation) {
      this.detailsListSensorstation.push({
        label: 'SENSORSTATION.NAME',
        value: this.sensorstation.name,
      });
      this.detailsListSensorstation.push({
        label: 'SENSORSTATION.ID',
        value: this.sensorstation.deviceId,
      });
      this.detailsListSensorstation.push({
        label: 'SENSORSTATION.SERIALNUMBER',
        value: this.sensorstation.aFESerialNumber,
      });
      this.detailsListSensorstation.push({
        label: 'SENSORSTATION.GROUPNAME',
        value: this.sensorstation.group,
      });
      this.detailsListSensorstation.push({
        label: 'SENSORSTATION.STREET',
        value: `${this.sensorstation.street} ${this.sensorstation.houseNumber}`,
      });
      this.detailsListSensorstation.push({
        label: 'SENSORSTATION.CITY',
        value: `${this.sensorstation.postalCode} ${this.sensorstation.city} `,
      });
      this.detailsListSensorstation.push({
        label: `${this.ts.instant('SENSORSTATION.LATITUDE')}, ${this.ts.instant(
          'SENSORSTATION.LONGITUDE'
        )}`,
        value: `${this.sensorstation.latitude}, ${this.sensorstation.longitude}`,
      });
    }
  }
}
