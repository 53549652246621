import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  //  ChangeDetectionStrategy,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Group, User, Vehicle, VehicleType, RoleConstants } from 'lcmm-lib-js';
import { UserService } from 'src/app/service/user.service';
import { Sort } from 'src/app/datasource/page';
import { LcmmDataSource } from 'src/app/datasource/lcmm-data-source';
/*
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
*/
import {
  getLeveledGroupName,
  getUsersObject,
  sortString,
} from 'src/app/utils/utils';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ResponsiveDesignService } from 'src/app/service/responsiveDesign.service';
import {
  NetworkService,
  NetworkStateEvent,
} from 'src/app/service/network.service';
import { VehicleDialogComponent } from './vehicle-dialog/vehicle-dialog.component';
import { VehicleCreateDialogComponent } from './vehicle-create-dialog/vehicle-create-dialog.component';
import { VehicleService, VehicleQuery } from '../../service/vehicle.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogEntity,
  ConfirmationDialogAction,
} from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
  standalone: false,
  /*
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed, void',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
      transition('* <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  */
})
export class VehiclesComponent implements OnInit, OnDestroy, AfterViewInit {
  private className = 'VehiclesComponent';

  public displayedColumns: string[] = [
    '_name',
    'group',
    'user',
    'lastTimestamp',
    'lastModified',
    'details',
  ];

  public users: User[];

  public drivers: User[];

  public filteredUsers: Observable<User[]>;

  public userFilter = new UntypedFormControl();

  public selectedUser: User;

  public groups: Group[] = [];

  public filteredGroups: Observable<Group[]>;

  public groupFilter = new UntypedFormControl();

  public selectedGroup: Group;

  public vehicleTypes: VehicleType[];

  public usersObject: { [id: string]: User } = {};

  public globalFilter = '';

  public pageSizeOptions: number[] = [5, 10, 25, 100];

  public pageSize = 10;

  private previousPageSize = -1;

  // Link dataSource to MatTable
  public initialSort: Sort = { property: '_name', order: 'asc' };

  public vehiclesData: LcmmDataSource<Vehicle, VehicleQuery>;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  @ViewChild(MatSort) public sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public vehicleService: VehicleService,
    private userService: UserService,
    public envService: EnvConfigurationService,
    private responsiveDesignService: ResponsiveDesignService,
    public networkService: NetworkService
  ) {
    this.vehiclesData = new LcmmDataSource<Vehicle, VehicleQuery>(
      'Vehicle',
      (request, query) => this.vehicleService.page(request, query),
      this.initialSort,
      { driver: undefined, group: undefined, global: undefined },
      this.networkService
    );
  }

  private resizePage(pageSize: number): void {
    if (this.vehiclesData.pageSize !== pageSize) {
      this.vehiclesData.fetch(0, pageSize);
    }
  }

  ngAfterViewInit(): void {
    this.resizePage(
      this.responsiveDesignService.register(
        this.className,
        this.resizePage.bind(this)
      )
    );
  }

  ngOnDestroy(): void {
    this.networkService.deregister(this.className);
    this.responsiveDesignService.deregister(this.className);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  private nsCallback(eventType: NetworkStateEvent, msg?: any): void {
    switch (eventType) {
      case NetworkStateEvent.ONLINE:
        this.vehiclesData.fetch();
        break;
      case NetworkStateEvent.CLIENTERROR:
      case NetworkStateEvent.SERVERERROR:
        this.paginator.pageIndex -= 1;
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.networkService.register(this.className, this.nsCallback.bind(this));
    this.vehicleService.vehicleTypes.subscribe((vehicleTypes) => {
      this.vehicleTypes = vehicleTypes;
    });
    this.userService.users.subscribe((users) => {
      if (users) {
        this.users = users.sort(sortString('userName'));
        this.drivers = users
          .filter((user) => user.userRole === RoleConstants.DRIVER)
          .sort(sortString('userName'));

        this.usersObject = getUsersObject(users);
        this.filteredUsers = this.userFilter.valueChanges.pipe(
          startWith(users),
          map((value) => this.filterUsers(value))
        );
      }
    });
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups;
        this.filteredGroups = this.groupFilter.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });
  }

  private filterUsers(value: string): User[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.users.filter((user) => {
        if (user.userName) {
          return user.userName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.users;
  }

  private filterGroups(value: string): Group[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.groups.filter((group) => {
        if (group.groupName) {
          return group.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.groups;
  }

  public getUserNames(userIds: string[]): string {
    let usernames = '';
    if (userIds !== undefined && userIds !== null && userIds.length > 0) {
      if (this.usersObject[userIds[0]]) {
        usernames = this.usersObject[userIds[0]].userName;
      }
      if (userIds.length > 1) {
        usernames += ` (+${userIds.length - 1})`;
      }
    }
    return usernames;
  }

  public openEdit(vehicle: Vehicle, allowEditing: boolean): void {
    const dialogRef = this.dialog.open(VehicleDialogComponent, {
      width: '80%',
      data: { vehicle, users: this.users, groups: this.groups, allowEditing },
    });

    dialogRef.afterClosed().subscribe((isChanged) => {
      if (isChanged != null && isChanged === true) {
        this.vehiclesData.fetch();
      }
    });
  }

  public create(): void {
    const dialogRef = this.dialog.open(VehicleCreateDialogComponent, {
      width: '80%',
      height: '80%',
      data: {
        vehicleTypeList: this.vehicleTypes,
        users: this.drivers,
        groups: this.groups,
      },
    });

    dialogRef.afterClosed().subscribe((returnedVehicle) => {
      if (returnedVehicle != null) {
        this.vehiclesData.fetch();
      }
    });
  }

  public enableVehicle(vehicle: Vehicle): void {
    this.vehicleService.enableVehicle(vehicle).subscribe(() => {
      this.vehiclesData.fetch();
    });
  }

  public disableVehicle(vehicle: Vehicle): void {
    const alterFunction = this.vehicleService.disableVehicle.bind(
      this.vehicleService
    );
    this.alterVehicle(
      vehicle,
      ConfirmationDialogAction.deactivate,
      alterFunction
    );
  }

  public deleteVehicle(vehicle: Vehicle): void {
    const alterFunction = this.vehicleService.deleteVehicle.bind(
      this.vehicleService
    );
    this.alterVehicle(vehicle, ConfirmationDialogAction.delete, alterFunction);
  }

  private alterVehicle(
    theVehicle: Vehicle,
    theAction: ConfirmationDialogAction,
    func: (v: Vehicle) => Observable<Vehicle>
  ) {
    const dialogData: ConfirmationDialogData = {
      entity: ConfirmationDialogEntity.vehicle,
      action: theAction,
      data: theVehicle.name,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((shoudlAlter) => {
      if (shoudlAlter) {
        func(theVehicle).subscribe(() => {
          this.vehiclesData.fetch();
        });
      }
    });
  }

  public displayUser(user: User): string {
    return user && user.userName ? user.userName : '';
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public resetPage(pageSize: number): void {
    this.vehiclesData.pageSize = pageSize;
    if (this.previousPageSize > 0 && this.previousPageSize !== pageSize) {
      this.paginator.firstPage();
    }
    this.previousPageSize = pageSize;
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    this.queryByFilter();
  }

  public removeGroup(): void {
    this.selectedGroup = undefined;
    this.groupFilter.reset();
    this.queryByFilter();
  }

  public selectUser(user: User): void {
    this.selectedUser = user;
    this.queryByFilter();
  }

  public removeUser(): void {
    this.selectedUser = undefined;
    this.userFilter.reset();
    this.queryByFilter();
  }

  public getCellClass(vehicleType: VehicleType): string {
    if (this.vehicleService.isElectric(vehicleType)) {
      return 'electricCell';
    }
    return null;
  }

  public queryByFilter(): void {
    this.vehiclesData.queryBy({
      driver: this.userFilter.value,
      group: this.groupFilter.value,
      global: this.globalFilter,
    });
  }

  public leveledGroupName(group: Group): string {
    return getLeveledGroupName(group);
  }

  public fetch(event: PageEvent): void {
    this.vehiclesData.fetch(event.pageIndex, event.pageSize);
  }
}
