<div class="sensorstationForm" formGroupName="sensorstationForm">
    <mat-form-field>
      <mat-label>{{ 'SENSORSTATION.NAME' | translate }}</mat-label>
      <input placeholder="{{ 'SENSORSTATION.NAME' | translate }}" required type="text" matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'SENSORSTATION.ID' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.ID' | translate }}" required type="text" matInput formControlName="deviceId" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.SERIALNUMBER' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.SERIALNUMBER' | translate }}" required type="text" matInput formControlName="aFESerialNumber" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.GROUPNAME' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.GROUPNAME' | translate }}" required type="text" matInput formControlName="group" />
      </mat-form-field>
    
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.STREET' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.STREET' | translate }}" required type="text" matInput formControlName="street" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.HOUSENR' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.HOUSENR' | translate }}" required type="text" matInput formControlName="houseNumber" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.CITY' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.CITY' | translate }}" required type="text" matInput formControlName="city" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.PLZ' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.PLZ' | translate }}" required type="text" matInput formControlName="postalCode" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.STATE' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.STATE' | translate }}" required type="text" matInput formControlName="state" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.LATITUDE' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.LATITUDE' | translate }}" required type="text" matInput formControlName="latitude" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SENSORSTATION.LONGITUDE' | translate }}</mat-label>
        <input placeholder="{{ 'SENSORSTATION.LONGITUDE' | translate }}" required type="text" matInput formControlName="longitude" />
      </mat-form-field>
</div> 
